import React, {useContext} from 'react';
import TablePagination from '@mui/material/TablePagination';
import {SiteContext} from "../utils/SiteContext";
import {styled} from "@mui/material/styles";


const Root = styled(TablePagination)(({theme}) => ({
    '& > *': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}))

export default function Pagination({page}) {
    const {loading} = useContext(SiteContext);
    const [, setLoading] = loading;
    const [pageVal, setPage] = page;

    const handleChangePage = (e, newPage) => {
        e.preventDefault();
        setLoading(true);
        setPage({...pageVal, page: newPage + 1});
    };

    const handleChangeRowsPerPage = (e) => {
        setLoading(true);
        setPage({pg_size: parseInt(e.target.value, 10), page: 1, count: pageVal.count});
    };

    return (
        <React.Fragment>
            <Root
                name={'pagination'}
                component="div"
                rowsPerPageOptions={[2, 10, 20, 25, 50, 100, 250]}
                count={pageVal.count}
                page={pageVal.page - 1}
                onPageChange={handleChangePage}
                rowsPerPage={pageVal.pg_size}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </React.Fragment>
    );
}