import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import {SiteContext} from "../../utils/SiteContext";
import CompanyListBox from "./CompanyListBox";
import {GetAllCompanies, GetUserCompanies, PatchUserCompanies} from "../../utils/AxiosUtils";
import {styled} from "@mui/material/styles";
import AddCompanyButton from "./AddCompanyButton";
import RefreshButton from "../../components/RefreshButton";
import StyledButton from "../../components/StyledButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteCompanyButton from "./DeleteCompanyButton";
import {ManageUsersContext} from "./ManageUsersContext";

const Root = styled('div')(({theme}) => ({
    position: "relative",
    padding: 0,
    margin: 20,
    width: 'auto',
    height: 'calc(100% - 40px)'
}))


const GridRoot = styled('div')(({theme}) => ({
    height: 'calc(100% - 64px)'
}))

const StyledBtn = styled(Button)(({theme}) => ({
    margin: theme.spacing(0.5, 0),
    minWidth: 10,
    width: '80%',
    "&:active": {
        transform: "translate(0px,3px)",
    }
}))
const DeleteBtn = styled(StyledButton)(({theme}) => ({
    float: 'right',
}))
const SaveButton = styled(StyledButton)(({theme}) => ({
    float: 'right',
}))

const blankCompanies = (setUserCompanies) => {
    setUserCompanies([]);
}

export default function UserCompanies({user}) {
    const {alert,loading} = useContext(SiteContext);
    const [, setAlertBar] = alert;
    const [userVal,] = user;
    const [loadingVal,]= loading;
    const [checked, setChecked] = useState([]);
    const [userCompanies, setUserCompanies] = useState([]);
    const [companiesVal, setCompanies] = useState([]);

    function not(a, b) {
        return a.filter((value) => b.findIndex((i) => i.id === value.id) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.findIndex((i) => i.id === value.id) !== -1);
    }

    let userCompaniesChecked = intersection(checked, userCompanies);
    let allCompaniesChecked = intersection(checked, companiesVal);

    const handleAllLeft = () => {
        setCompanies(companiesVal.concat(userCompanies));
        setUserCompanies([]);
    };

    const handleCheckedLeft = () => {
        setCompanies(companiesVal.concat(userCompaniesChecked));
        setUserCompanies(not(userCompanies, userCompaniesChecked));
        setChecked(not(checked, userCompaniesChecked));
    };

    const handleCheckedRight = () => {
        setUserCompanies(userCompanies.concat(allCompaniesChecked));
        setCompanies(not(companiesVal, allCompaniesChecked));
        setChecked(not(checked, allCompaniesChecked));
    };

    const handleAllRight = () => {
        setUserCompanies(userCompanies.concat(companiesVal));
        setCompanies([]);
    };

    useEffect(() => {
        if (!userVal) {
            blankCompanies(setUserCompanies);
            // GetAllCompanies(setAllCompanies);
            return;
        }
        GetUserCompanies(userVal, setUserCompanies, setCompanies, setChecked);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userVal,loadingVal]);

    const handleSave = () => {
        PatchUserCompanies(userVal, userCompanies, setAlertBar);
    }

    return (
        <Root>
            <Typography variant="h6"  component="div">
                User Companies
            </Typography>
            <RefreshButton
                id={'userCompRefreshBtn'}
                onClick={() =>
                    GetUserCompanies(userVal, setUserCompanies, setCompanies, setChecked)}
            />
            <GridRoot>
                <Grid
                    container
                    spacing={2}
                    sx={{height: `100%`}}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={5} md={5} sx={{height: '100%'}}
                          data-cy={"UserCompanies"}>
                        <h4 style={{padding:0,margin:0}}>Available</h4>
                        <CompanyListBox
                            items={companiesVal}
                            checkBoxes={[checked, setChecked]}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container alignItems={"center"} direction={"column"}>
                            <StyledBtn
                                id={'allRightBtn'}
                                variant={"contained"}
                                color={"secondary"}
                                size="small"
                                onClick={handleAllRight}
                                disabled={companiesVal.length === 0}
                                aria-label="move all right"
                            >
                                ≫
                            </StyledBtn>
                            <StyledBtn
                                id={'someRightBtn'}
                                variant={"contained"}
                                color={"secondary"}
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={allCompaniesChecked.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </StyledBtn>
                            <StyledBtn
                                id={'someLeftBtn'}
                                variant={"contained"}
                                color={"secondary"}
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={userCompaniesChecked.length === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </StyledBtn>
                            <StyledBtn
                                id={'allLeftBtn'}
                                variant={"contained"}
                                color={"secondary"}
                                size="small"
                                onClick={handleAllLeft}
                                disabled={userCompanies.length === 0}
                                aria-label="move all left"
                            >
                                ≪
                            </StyledBtn>
                        </Grid>
                    </Grid>
                    <Grid item xs={5} md={5} sx={{height: '100%'}}
                        data-cy={"AllCompanies"}
                    >
                        <h4 style={{padding:0,margin:0}}>Subscribed To</h4>
                        <CompanyListBox
                            items={userCompanies}
                            checkBoxes={[checked, setChecked]}
                        />
                    </Grid>
                </Grid>
            </GridRoot>
            <SaveButton
                id={'userCompSaveBtn'}
                variant={"contained"}
                color={"primary"}
                startIcon={<SaveIcon/>}
                onClick={handleSave}
            >Update Companies</SaveButton>
            <DeleteCompanyButton />
            <AddCompanyButton/>
        </Root>
    )
}