import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {SiteContext} from "../../utils/SiteContext";
import Gauge from "../../components/Gauge";
import {GetData} from "../../utils/AxiosUtils";
import {StyledTableBody, StyledTableCell, StyledTableRow, TableRoot} from "../../site components/StyledTable";


function Row(props) {
    const {row, onClick} = props;

    return (
        <React.Fragment>
            <StyledTableRow key={row.id} onClick={onClick}>
                <StyledTableCell component="th" scope="row" style={{paddingLeft: '10px'}}>
                    {row.item_name}
                </StyledTableCell>
                <StyledTableCell align="left">{row.cust_part}</StyledTableCell>
                <StyledTableCell align="center">
                    <Gauge
                        curr={row.avail}
                        min={row.min_qty}
                        max={row.max_qty}
                        radius={25}
                    />
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number,
        item_name: PropTypes.string,
        cust_part: PropTypes.string,
        avail: PropTypes.number,
        warehouse: PropTypes.number,
        min_qty: PropTypes.number,
        max_qty: PropTypes.number,
    }).isRequired,
};

export default function Top5InvItems() {
    const {loading} = useContext(SiteContext);
    const [dataVal, setData] = useState({count: 0, results: []});
    const [loadingVal, setLoading] = loading;
    const page = useState({page: 1, pg_size: 5, count: 0});
    // const [,setSearch] = search;

    useEffect(() => {
        setLoading(false);
        GetData('InvAllList',null, setData, page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingVal]);

    return (
        // Overwrite the maxHeight here because there is no search bar or pagination.
        <TableRoot component={Paper} style={{maxHeight: '100%'}}>
            <Table stickyHeader aria-label="Lowest 5 inv items">
                <TableHead>
                    <TableRow key={'headers'}>
                        <StyledTableCell align="left" style={{paddingLeft: '10px'}}>WCA #</StyledTableCell>
                        <StyledTableCell align="left">Part #</StyledTableCell>
                        <StyledTableCell align="center">Inventory<br/> Level</StyledTableCell>
                    </TableRow>
                </TableHead>
                <StyledTableBody>
                    {dataVal && dataVal.results.map((row) => (
                        <Row key={row.id} row={row}/>
                    ))}
                </StyledTableBody>
            </Table>
        </TableRoot>
    );
}