import {styled} from "@mui/material/styles";

export const PageContentArea = styled('div')(({theme}) => ({
    position: "absolute",
    padding: 0,
    margin: 20,
    left: theme.sideBar.width,
    top: theme.appBar.height,
    width: `calc(100vw - ${theme.sideBar.width}px - 40px)`,
    height: `calc(100vh - ${theme.appBar.height}px - 40px)`,
}))