import React from 'react';
import {createRoot} from 'react-dom/client';
import './css/index.css';
import App from './App';
import {SiteProvider} from "./utils/SiteContext";
import {grey} from "@mui/material/colors";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {CacheProvider} from "@emotion/react";
import createCache from '@emotion/cache'

const theme = createTheme({
    palette: {
        primary: {
            light: '#0082cb', main: '#0082cb', dark: '#53637a', contrastText: '#ffffff',
        }, secondary: {
            light: '#ff7961', main: '#23527c', dark: '#ba000d', contrastText: '#ffffff',
        }, background: {
            main: grey[100], contrastText: grey[900],
        }
    }, sideBar: {
        width: 240, palette: {
            primary: {
                main: grey[100]
            },
        }
    }, appBar: {
        height: 128,
    }
});


const cache = createCache({
    key: 'jss-class-prefix-key',
    nonce: document.querySelector('meta[property="csp-nonce"]')?.content || /* istanbul ignore next */  '',
    prepend: true,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <CacheProvider value={cache}>
         <SiteProvider>
             <ThemeProvider theme={theme}>
                 <App/>
             </ThemeProvider>
         </SiteProvider>
    </CacheProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
