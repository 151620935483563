import axios from "axios";
import {getCookie} from "./Utilities";

export const site_axios = axios.create({
    // baseURL: 'http://localhost:8000/Portal/api/',
    baseURL: 'http://portal.wcaoem.com/Portal/api/',
    withCredentials: true, headers: {
        "Content-Type": 'application/json', 
        // "X-CSRFTOKEN": getCookie('csrftoken'),
    }, data: {},
})

export const GetLogout = (setLoading, setUserInfo, setTab, setAlertBar) => {
    setLoading(true);
    setUserInfo({loggedIn: false, name: "", staff: false, id: ""});
    setTab({page: 'DashboardPage', props: ""});
    setAlertBar({
        open: false, alertType: "info", alertMsg: "", time: 60
    });
    site_axios.get('Logout/', {},).then(() => {

    });
}

export const PostSignUp = (formData, setFormCheck, form, setOpen, setAlertBar, setLoading) => {
    site_axios.get('GetCsrf/',).then((csrf) => {
        if (csrf === false) {
            console.log("No csrf");
            return;
        }
        site_axios.post('SignUp/', formData, {
            headers: {
                "X-CSRFTOKEN": getCookie('csrftoken'),
            }
        }).then(() => {
            setFormCheck(form);
            setOpen(false);
            setAlertBar({
                open: true, alertType: "success", alertMsg: "Account was successfully created.", time: 2000
            });
            setLoading(true);
        }).catch((any) => {
            try {
                console.log(any)
                const errors = any.response.data.errors
                setFormCheck({...errors});
            } catch (err) {
                console.log(err)
                setAlertBar({
                    open: true, alertType: "error", alertMsg: err, time: 2000
                });
            }
        })
    }).catch(() => {});
}

export const GetCsrf = () => {
    let gotCsrf = false;
    site_axios.get('GetCsrf/',).then(() => {
        gotCsrf = true;
    }).catch(() => {
        gotCsrf = false;
    });
    return gotCsrf;
}

export const GetUserList = (searchVal, filter, setData, page) => {
    const [pageVal, setPage] = page;
    site_axios.get('UsersList', {
        params: {
            "search": searchVal, "filter": filter, "page": pageVal.page, "page_size": pageVal.pg_size,
        }
    },).then((data) => {
        setData(data.data);
        setPage({page: pageVal.page, pg_size: pageVal.pg_size, count: data.data.count});
    }).catch(() => {
    });
}

export const PatchUserDetails = (userVal, userDetails, setAlertBar, setLoading, setFormCheck) => {
    site_axios.patch(`UsersList/${userVal}/`, {userDetails}, {
        headers: {
            "X-CSRFTOKEN": getCookie('csrftoken'),
        }
    }).then((data) => {
        if (data.data.detail === 'Updated') {
            setAlertBar({
                open: true, alertType: "success", alertMsg: "User updated.", time: 2000
            })
            setLoading(true);
        }
    }).catch((e) => {
        switch (e.response.status) {
            case 400:
                setFormCheck({...e.response.data.errors});
                setAlertBar({
                    open: true, alertType: "error", alertMsg: "Error with form.", time: 2000
                })
                break;
            case 404:
                setAlertBar({
                    open: true, alertType: "error", alertMsg: "User not found.", time: 2000
                })
                break
            default:
                setAlertBar({
                    open: true, alertType: "error", alertMsg: "An unknown error occurred.", time: 2000
                })
        }
    });
}

export const GetUserDetails = (userVal, setUserDetails) => {
    site_axios.get(`UsersList/${userVal}/`, {},).then((data) => {
        setUserDetails(data.data);
    }).catch(() => {
    });
}

export const DeleteUser = (val, setLoading, setVal) => {
    site_axios.delete(`UsersList/${val}/`, {
        headers: {
            "X-CSRFTOKEN": getCookie('csrftoken'),
        }
    }).then(() => {
        setLoading(true);
        setVal("")
    }).catch(() => {

    });
}

export const PatchUserCompanies = (userVal, userCompanies, setAlertBar) => {
    site_axios.patch(`UpdateUserCompanies/`, {
        user: userVal, userCompanies: userCompanies
    }, {
        headers: {
            "X-CSRFTOKEN": getCookie('csrftoken'),
        }
    }).then((data) => {
        if (data.data.detail === 'Updated') {
            setAlertBar({
                open: true, alertType: "success", alertMsg: "User updated.", time: 2000
            })
        }
    }).catch(() => {
    });
}

export const GetCompanyDetails = (setCustCompany) => {
    site_axios.get('CompanyDetail/',
        {},
    ).then(r =>{
        setCustCompany(r.data)
    })
}

export  const  PostCompanyList = (form, setFormErrors, setLoading, setAlertBar, setCompanies) => {
    // Since we are sending a file we need to create a form data object.
    let formData = new FormData()
    formData.append('company_name',form.fullname)
    formData.append('short_name',form.shortname)

    if(form.logo)
        formData.append('logo',form.logo[0])

     return site_axios.post(`CompanyList/`,
        formData,
        {
            headers: {
                "X-CSRFTOKEN": getCookie('csrftoken'),
                "Content-Type": 'multipart/form-data',
            }
        }).then((e) => {
            setAlertBar({
                open: true, alertType: "success", alertMsg: "Company Added.", time: 2000
             })
            setLoading(false);
            GetAllCompanies(setCompanies);
            return Promise.resolve(true);
         }).catch((e) => {
            setFormErrors(e.response.data.errors);
            return Promise.resolve(false);
    });



}

export const GetAllCompanies = (setCompanies) => {
    site_axios.get(`CompanyList/`, {},).then((data) => {
        setCompanies(data.data);
    }).catch(() => {
    });
    return 0;
}

export const GetNonUserCompanies = (userComp, setAllCompanies) => {
    let comps = [];
    site_axios.get(`CompanyList/`, {},).then((data) => {
        comps = data.data;
        comps = comps.filter((ele) => {
            let index = userComp.findIndex((i) => i.id === ele.id);
            return index === -1;
        })
        setAllCompanies(comps);
    }).catch(() => {
    });
}

export const GetUserCompanies = (userVal, setUserCompanies, setAllCompanies, setChecked) => {
    let userComp = [];
    setChecked([]);
    site_axios.get(`UserCompanyList/${userVal}/`, {}).then((data) => {
        data.data.forEach((row) => {
            userComp.push(row.company);
        });
        setUserCompanies(userComp);
        GetNonUserCompanies(userComp, setAllCompanies);
    }).catch(() => {
    });
}

export const GetLoggedIn = (setUserInfo) => {
    site_axios.get('LoggedIn/', {
        withCredentials: true
    }).then((response) => {
        const state = response.data;
        if (state.detail === 'Logged in') {
            setUserInfo({
                loggedIn: true, name: state.name, staff: state.staff, id: state.id, company: state.company
            });
        } else {
            setUserInfo(false);
        }
    });

};

export const GetSignIn = (e, account, setUserInfo, userInfoVal, setAccount, setAlertBar) => {
    e.preventDefault();
    site_axios.get('GetCsrf/',).then(() => {
        site_axios.post('Login/', {
            Email: account.email, Password: account.password,
        }, {
            headers: {
                "X-CSRFTOKEN": getCookie('csrftoken'),
            }
        }).then(() => {
            //Verify that we did get a session cookie. This also causes the page to re-render
            // if the loginStatus did change.
            GetLoggedIn(setUserInfo);
            if (userInfoVal.loggedIn) {
                //Clear the user account info
                setAccount({email: '', password: ''});
            }
        }).catch((e) => {
            if (e.response.status === 400) {
                setAlertBar({
                    open: true, alertType: "error", alertMsg: e.response.data.detail, time: 5000
                })
            }
        });
    });
}

export const GetDownload = (searchVal, endpoint) => {
    site_axios.get(endpoint, {
        params: {
            "search": searchVal,
        }, responseType: 'blob',
    },).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const options = {year: 'numeric', month: 'numeric', day: 'numeric'};
        const filename = "WCA " + endpoint + ' ' + new Date().toLocaleDateString('en-US', options) + ".xlsx"
        link.setAttribute('download', filename);
        link.click();
    })
}

export function GetData(endpoint, searchVal, setData, page) {
    const [pageVal, setPage] = page;
    site_axios.get(endpoint, {
        params: {
            "search": searchVal, "page": pageVal.page, "page_size": pageVal.pg_size,
        }
    },).then((data) => {
        setData(data.data);
        setPage({...pageVal, count: data.data.count})
        return 0;
    });
}

export const GetInvSummary = (setInvSummary) => {
    site_axios.get('InvSummary/', {},).then((data) => {
        setInvSummary(data.data.detail)
    });
}

export const PostChangePassword = ({formState, errorFormState, setAlertBar}) => {
    const [formVal, setForm] = formState;
    const [, setErrorForm] = errorFormState;
    site_axios.post('ChangePassword/', {
        'password': formVal.password, 'new_password': formVal.new_password, 'new_password2': formVal.new_password2
    }, {
        headers: {
            "X-CSRFTOKEN": getCookie('csrftoken'),
        }
    }).then(() => {
        setForm({
            password: '', new_password: '', new_password2: ''
        })
        setAlertBar({
            open: true, alertType: "success", alertMsg: "Password changed.", time: 2000
        })
    }).catch((e) => {
        setForm({
            password: '', new_password: '', new_password2: ''
        })
        const match_error = "New passwords must match."

        if(e.response.data.errors.__all__ && e.response.data.errors.__all__[0]===match_error){
         setErrorForm({password:'', new_password: match_error, new_password2: match_error })
        } else {
            setErrorForm({...e.response.data.errors});
        }
    })
}

export const GetWorkOrderLines = (order, setData, page) => {
    const [pageVal, setPage] = page;
    site_axios.get('WorkOrderLinesList', {
        params: {
            "work_order": order, "page": pageVal.page, "page_size": pageVal.pg_size,
        }
    },).then((data) => {
        setData(data.data);
        setPage({...pageVal, count: data.data.count})
        return 0;
    });
}

export const GetWorkOrderDetails = (order, setData) => {
    site_axios.get(`WorkOrderDetails/${order}/`, {},).then((data) => {
        setData(data.data);
        return 0;
    })
}

export const GetInvoiceLines = (invoice, setData, page) => {
    const [pageVal, setPage] = page;
    site_axios.get('InvoiceLinesList', {
        params: {
            "invoice": invoice, "page": pageVal.page, "page_size": pageVal.pg_size,
        }
    },).then((data) => {
        setData(data.data);
        setPage({...pageVal, count: data.data.count})
        return 0;
    });
}

export const GetInvoiceDetails = (invoice, setData) => {
    site_axios.get(`InvoiceDetails/${invoice}/`, {

    },).then((data) => {
        setData(data.data);
        return 0;
    })
}

export const DeleteCompany = (company, setCompanies,setLoading, setAlertBar ) =>{
    site_axios.delete(`CompanyDelete/${company.id}/`, {
        headers:{
            "X-CSRFTOKEN": getCookie('csrftoken'),
        }
    }).then(()=> {
        setLoading(true);
        setAlertBar({
            open: true, alertType: "success", alertMsg: "Company Deleted.", time: 2000
        })
        GetAllCompanies(setCompanies);
    })
}

// export const DeleteUser = (val, setLoading, setVal) => {
//     site_axios.delete(`UsersList/${val}/`, {
//         headers: {
//             "X-CSRFTOKEN": getCookie('csrftoken'),
//         }
//     }).then(() => {
//         setLoading(true);
//         setVal("")
//     }).catch(() => {
//
//     });
// }
