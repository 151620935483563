import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {OrdersPageContext} from "./WorkOrdersContext";
import {GetWorkOrderDetails, GetWorkOrderLines} from "../../utils/AxiosUtils";
import {StyledTableBody, StyledTableCell, StyledTableRow, TableRoot} from "../../site components/StyledTable";

function Row(props) {
    const {orderData, orderDetailsData} = useContext(OrdersPageContext);
    const [,setOrderData] = orderData;
    const [,setOrderDetailsData] = orderDetailsData;
    const {row,openModal} = props;
    const  [orderPageVal,setPage] = React.useState([]);
    const orderPage = [orderPageVal,setPage]

    return (
        <React.Fragment>
            <StyledTableRow key={row.id}  onClick={
                () => {
                    GetWorkOrderLines(row.work_order, setOrderData, orderPage);
                    GetWorkOrderDetails(row.id,setOrderDetailsData);
                    openModal(true);
                }}>
                <StyledTableCell align="left" style={{paddingLeft: 35}}>{row.work_order}</StyledTableCell>
                <StyledTableCell align="left">{row.wo_date}</StyledTableCell>
                <StyledTableCell align="left">{row.quote}</StyledTableCell>
                <StyledTableCell align="left">{row.cust_po}</StyledTableCell>
                <StyledTableCell align="left">{row.invoice}</StyledTableCell>
                <StyledTableCell align="left">{row.status}</StyledTableCell>
                {/*<StyledTableCell align="left">{row.sales_person}</StyledTableCell>*/}
                <StyledTableCell align="left">{row.ship_to}</StyledTableCell>
                <StyledTableCell align="left">{row.due_date}</StyledTableCell>

            </StyledTableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number,
        work_order: PropTypes.string,
        wo_date: PropTypes.string,
        quote: PropTypes.string,
        cust_po: PropTypes.string,
        invoice: PropTypes.string,
        status: PropTypes.string,
        sales_person: PropTypes.string,
        ship_to: PropTypes.string,
        due_date: PropTypes.string,
    }).isRequired,
};

export default function WorkOrdersTable(props) {
    const {data} = useContext(OrdersPageContext);
    const [dataVal,] = data;
    const {openModal} = props;

    return (
        <TableRoot component={Paper}>
            <Table stickyHeader aria-label="work order table">
                <TableHead>
                    <TableRow key={'headers'}>
                        <StyledTableCell align="left" style={{paddingLeft: 35}}>WorkOrder</StyledTableCell>
                        <StyledTableCell align="left">WO Date</StyledTableCell>
                        <StyledTableCell align="left">Quote</StyledTableCell>
                        <StyledTableCell align="left">Customer PO#</StyledTableCell>
                        <StyledTableCell align="left">Invoice</StyledTableCell>
                        <StyledTableCell align="left">Status</StyledTableCell>
                        {/*<StyledTableCell align="left">Sales Person</StyledTableCell>*/}
                        <StyledTableCell align="left">Ship To</StyledTableCell>
                        <StyledTableCell align="left">Due Date</StyledTableCell>
                    </TableRow>
                </TableHead>
                <StyledTableBody>
                    {dataVal && dataVal.results.map((row) => (
                        <Row key={row.id} row={row} openModal={openModal}/>
                    ))}
                </StyledTableBody>
            </Table>
        </TableRoot>
    );
}