export const getCookie = (cookie) => {
    let data = (document.cookie).split('; ');
    for (let i = 0; i < data.length; i++) {
        let tok = data[i].split('=');
        if (tok[0] === cookie) {
            return tok[1];
        }
    }
};


