import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {InvoicePageContext} from "./InvoiceContext";
import {GetInvoiceDetails, GetInvoiceLines} from "../../utils/AxiosUtils";
import {StyledTableBody, StyledTableCell, StyledTableRow, TableRoot} from "../../site components/StyledTable";


function Row(props) {
    const {invoiceData, invoiceDetailsData} = useContext(InvoicePageContext);
    const [,setInvoiceData] = invoiceData;
    const [,setInvoiceDetailsData] = invoiceDetailsData;
    const {row,openModal} = props;
    const  [invoicePageVal,setPage] = React.useState([]);
    const invoicePage = [invoicePageVal,setPage]

    return (
        <React.Fragment>
            <StyledTableRow key={row.id}  onClick={
                () => {
                    GetInvoiceLines(row.invoice, setInvoiceData, invoicePage);
                    GetInvoiceDetails(row.id,setInvoiceDetailsData);
                    openModal(true);
                }}>
                <StyledTableCell align="left" style={{paddingLeft: 35}}>{row.invoice}</StyledTableCell>
                <StyledTableCell align="left">{row.invoice_date}</StyledTableCell>
                <StyledTableCell align="left">{row.quote}</StyledTableCell>
                <StyledTableCell align="left">{row.cust_po}</StyledTableCell>
                <StyledTableCell align="left">{row.work_order}</StyledTableCell>
                {/*<StyledTableCell align="left">{row.batch}</StyledTableCell>*/}
                <StyledTableCell align="left">${row.total}</StyledTableCell>
                <StyledTableCell align="left">{row.paid}</StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number,
        invoice: PropTypes.string,
        invoice_date: PropTypes.string,
        quote: PropTypes.string,
        work_order: PropTypes.string,
        cust_po: PropTypes.string,
        batch: PropTypes.string,
        total: PropTypes.string,
        paid: PropTypes.string,
    }),
};

export default function InvoiceTable(props) {
    const {data} = useContext(InvoicePageContext);
    const [dataVal,] = data;
    const {openModal} = props;

    return (
        <TableRoot component={Paper}>
            <Table stickyHeader aria-label="invoice table">
                <TableHead>
                    <TableRow key={'headers'}>
                        <StyledTableCell align="left" style={{paddingLeft: 35}}>Invoice</StyledTableCell>
                        <StyledTableCell align="left">Invoice Date</StyledTableCell>
                        <StyledTableCell align="left">Quote</StyledTableCell>
                        <StyledTableCell align="left">Customer PO#</StyledTableCell>
                        <StyledTableCell align="left">Work Order</StyledTableCell>
                        {/*<StyledTableCell align="left">Batch</StyledTableCell>*/}
                        <StyledTableCell align="left">Total</StyledTableCell>
                        <StyledTableCell align="left">Status</StyledTableCell>
                    </TableRow>
                </TableHead>
                <StyledTableBody>
                    {dataVal && dataVal.results.map((row) => (
                        <Row key={row.id} row={row} openModal={openModal}/>
                    ))}
                </StyledTableBody>
            </Table>
        </TableRoot>
    );
}