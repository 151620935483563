import React, {useContext, useEffect, useState} from "react";
import {SiteContext} from "../../utils/SiteContext";
import {GetUserDetails} from "../../utils/AxiosUtils";
import Grid from "@mui/material/Grid";
import Glass from "../../components/Glass";
import AccountDetails from "./AccountDetails";
import ChangePassword from "./ChangePassword";
import {PageContentArea} from "../../site components/PageContentArea";

const leftSide = (userDetails) => {
    return (
        <Grid container gap={2} sx={{height: '100%'}}>
            <Grid item xs={12} sx={{minHeight: '435px'}}>
                <Glass>
                    <AccountDetails userDetail={userDetails}/>
                </Glass>
            </Grid>
            <Grid item xs={12} sx={{minHeight: '265px'}}>
                <Glass>
                    <ChangePassword />
                </Glass>
            </Grid>
        </Grid>
    )
}

const rightSide = () => {
    return (
        <Grid container spacing={0} sx={{height: '100%'}}>
            <Grid item xs={12} sx={{height: '100%'}}>
                <Glass/>
            </Grid>
        </Grid>
    )
}

export default function AccountPage() {
    const {userInfo} = useContext(SiteContext);
    const [userDetails, setUserDetails] = useState({});
    const [user,] = userInfo;

    useEffect(() => {
        GetUserDetails(user.id, setUserDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    return (
        <PageContentArea>
            <Grid container gap={2} sx={{height: '100%'}}>
                <Grid item xs={12} md={4} sx={{height: '100%'}}>
                    {leftSide([userDetails, setUserDetails])}
                </Grid>
                <Grid item xs={12} md={4} sx={{height: '100%'}}>
                    {rightSide()}
                </Grid>
            </Grid>
        </PageContentArea>
    )
}