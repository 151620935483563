import React, {useContext, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {GetSignIn} from "../../utils/AxiosUtils";
import {SiteContext} from "../../utils/SiteContext";
import {styled} from "@mui/material/styles";
import StyledButton from "../../components/StyledButton";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="wcaoem.com">
                Whalley Computer Associates OEM
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const FormRoot = styled('div')(({theme}) => ({
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}))

const StyledAvatar = styled(Avatar)(({theme}) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
}))

const StyledForm = styled("form")(({theme}) => ({
    width: '100%',
    marginTop: theme.spacing(1),
}))

const SubmitButton = styled(StyledButton)(({theme}) => ({
    margin: theme.spacing(3, 0, 2),
}))

export default function Login({setOpen}) {
    const {userInfo, alert} = useContext(SiteContext);
    const [userInfoVal, setUserInfo] = userInfo;
    const [, setAlertBar] = alert;
    const [account, setAccount] = useState({email: '', password: ''})
    const SignIn = (e) => GetSignIn(e, account, setUserInfo, userInfoVal, setAccount, setAlertBar);

    return (
        <Container component="main" maxWidth="xs">
            <FormRoot>
                <StyledAvatar>
                    <LockOutlinedIcon/>
                </StyledAvatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <StyledForm noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => {
                            setAccount({...account, email: e.target.value});
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => {
                            setAccount({...account, password: e.target.value});
                        }}
                    />
                    <SubmitButton
                        type="submit"
                        fullWidth
                        name={"signin"}
                        variant="contained"
                        color="primary"
                        onClick={SignIn}
                    >
                        Sign In
                    </SubmitButton>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                {/*Forgot password?*/}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2" onClick={() => {
                                setOpen(true);
                            }}>
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </StyledForm>
            </FormRoot>
            <Box mt={8}>
                <Copyright/>
            </Box>
        </Container>

    );
}