import React, {useContext, useEffect} from "react";
import TopAppBar from "../../site components/TopAppBar";
import SideBar from "../../site components/SideBar";
import InventoryPage from "../inventory/InventoryPage";
import DashboardPage from "../dashboard/DashboardPage";
import {SiteContext} from "../../utils/SiteContext";
import CustomPage from "../custom page/CustomPage";
import ManageUsersPage from "../manage users/ManageUsersPage";
import {styled} from "@mui/material/styles";
import AccountPage from "../account/AccountPage";
import WorkOrdersPage from "../work orders/WorkOrdersPage"
import {InventoryPageProvider} from "../inventory/InventoryContext";
import {OrdersPageProvider} from "../work orders/WorkOrdersContext";
import {InvoicePageProvider} from "../invoice page/InvoiceContext";
import InvoicePage from "../invoice page/InvoicePage";
import {ManageUsersProvider} from "../manage users/ManageUsersContext";
import {GetCompanyDetails} from "../../utils/AxiosUtils";

const MainPage = styled('div')(({theme}) => ({
    display: 'flex', flexDirection: 'row', padding: 0, margin: 0,
}))

const getTab = (tabVal) => {
    switch (tabVal.page) {
        case 'Dashboard':
            return (<DashboardPage/>);
        case 'Inventory':
            return (<InventoryPageProvider>
                    <InventoryPage searchProp={tabVal.props}/>
                </InventoryPageProvider>);
        case 'Work Orders':
            return (<OrdersPageProvider>
                    <WorkOrdersPage searchProp={tabVal.props}/>
                </OrdersPageProvider>);
        case 'Invoices':
            return (<InvoicePageProvider>
                <InvoicePage searchProp={tabVal.props}/>
            </InvoicePageProvider>);
        case 'Custom Page':
            return (<CustomPage/>);
        case 'Manage Users':
            return (<ManageUsersProvider>
                <ManageUsersPage/>
            </ManageUsersProvider>);
        case 'Account':
            return (<AccountPage/>)
        /* istanbul ignore next */
        default:
            return (<DashboardPage/>);
    }
}

export default function ClientPortal() {
    const {tab,custCompany} = useContext(SiteContext);
    const [tabVal,] = tab;
    const [custCompanyVal,setCustCompany] = custCompany;

    useEffect(()=>{
        GetCompanyDetails(setCustCompany)
    },[])

    return (<React.Fragment>
            <TopAppBar/>
            <MainPage>
                <SideBar/>
                {getTab(tabVal)}
            </MainPage>
        </React.Fragment>)
}
