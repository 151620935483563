import React, {useContext, useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import StyledTextField from "../../components/StyledTextField";
import StyledButton from "../../components/StyledButton";
import {PostChangePassword} from "../../utils/AxiosUtils";
import {SiteContext} from "../../utils/SiteContext";

const Root = styled('div')(({theme}) => ({
    padding: 0,
    margin: 10,
}))

const DetailField = styled(StyledTextField)(({theme}) => ({
    margin: 0,
    marginLeft: 10,
    marginRight: 10,
    padding: 0,
    width: `calc(100% - 20px)`,
}))

const StyledForm = styled("form")(({theme}) => ({
    width: '100%',
    marginTop: theme.spacing(1),
}))
export default function ChangePassword() {
    const {alert,} = useContext(SiteContext)
    const [,setAlertBar] = alert

    const [form, setForm] = useState({
        'password':'',
        'new_password':'',
        'new_password2':''
    })
    const [formErrors,setFormErrors] = useState({
        'password':'',
        'new_password':'',
        'new_password2':''
    })
    const formState = [form, setForm]
    const errorFormState =[formErrors,setFormErrors]

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Root>
            <Typography variant={'h6'} sx={{margin: '10px'}}>
                Change Password
            </Typography>
            <StyledForm>
            <Grid container spacing={1.25}>
                <Grid item sm={12}>
                    <DetailField
                        inputProps={{maxLength: 128}}
                        id={'password'}
                        label={"Current password"}
                        type={"password"}
                        autoComplete={"current-password"}
                        value={form.password ? form.password : ""}
                        onChange={(e) => {
                            setForm({...form, password: e.target.value});
                        }}
                        error={/* istanbul ignore next */ (formErrors.password && formErrors.password !== '') ||/* istanbul ignore next */ false}
                        helperText={formErrors.password}
                    />
                </Grid>
                <Grid item sm={12}>
                    <DetailField
                        inputProps={{maxLength: 128}}
                        id={'new_password'}
                        value={form.new_password ? form.new_password : ""}
                        label={"New password"}
                        type={"password"}
                        autoComplete={"new-password"}
                        onChange={
                            (e) => {
                                setForm({...form, new_password: e.target.value});
                            }}
                        error={(formErrors.new_password && formErrors.new_password !== '') || false}
                        helperText={formErrors.new_password}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DetailField
                        inputProps={{maxLength: 128}}
                        id={'new_password2'}
                        type={"password"}
                        autoComplete={"new-password"}
                        value={form.new_password2 ? form.new_password2 : ""}
                        label={"Confirm new password"}
                        onChange={
                            (e) => {
                                setForm({...form, new_password2: e.target.value});
                            }}
                        error={(formErrors.new_password2 && formErrors.new_password2 !== '') || false}
                        helperText={formErrors.new_password2}
                    />
                </Grid>
            </Grid>
            <StyledButton
                name={'changePasswordBtn'}
            onClick={()=>{
                PostChangePassword({formState,errorFormState,setAlertBar})
            }}
            >Change Password</StyledButton>
            </StyledForm>
        </Root>
    )
}