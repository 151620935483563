import {styled} from "@mui/material/styles";
import TextField from "@mui/material/TextField";


const StyledTxtField = styled(TextField)(({theme}) => ({
    margin: 0,
    marginLeft: 0,
    padding: 0,
    width: '100%',
}))

export default function StyledTextField(props) {

    return (
        <StyledTxtField
            variant={'outlined'}
            size={'small'}
            color={'primary'}
            {...props}
        >{props.children}
        </StyledTxtField>
    )
}