import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import React, {useContext, useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import PropTypes from "prop-types";
import {GetUserList} from "../../utils/AxiosUtils";
import {SiteContext} from "../../utils/SiteContext";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {styled} from "@mui/material/styles";
import FilterButton from "../../components/FilterButton";


const BodyCell = styled(TableCell)(({theme}) => ({
    margin: 0,
    paddingBottom: 0,
    paddingTop: 5,
    verticalAlign: "center",
    bottom: 0,
}))

const HeaderCell = styled(TableCell)(({theme}) => ({
    margin: 0,
    padding: 0,
    paddingRight: 5,
    paddingLeft: 15,
}))

const StyledTableRow = styled(TableRow)(({theme}) => ({
    margin: 0,
    padding: 0,
    height: 32,
    '& > *': {
        borderBottom: 'unset',
    },
    "&:hover": {
        backgroundColor: '#F0F0F0',
    },
}))

const CheckMark = styled(CheckCircleIcon)(({theme}) => ({
    width: '20px',
    margin: '0px',
    padding: '0px',
}))

const StyledTableBody = styled(TableBody)(({theme}) => ({
    margin: 'auto',
    maxWidth: '95%',
    maxHeight: `calc(95vh - 128px - ${theme.appBar.height}px)`,
    overflow: "auto",
}))

const StyledContainer = styled(TableContainer)(({theme}) => ({
    margin: 'auto',
    maxWidth: '95%',
    maxHeight: `calc(95vh - 128px - ${theme.appBar.height}px)`,
    overflow: "auto",
}))

function Row(props) {
    const {row, user} = props;
    const [userVal, setUser] = user;

    return (
        <React.Fragment>
            <StyledTableRow
                key={row.id + '_top'}
                onClick={() => setUser(row.id)}
                sx={row.id === userVal ? {backgroundColor: "rgba(8,128,246,0.35)"} : {}}
            >
                <BodyCell component="th" scope="row">
                    {row.first_name + ' ' + row.last_name}
                </BodyCell>
                <BodyCell align="left">{row.primary_company}</BodyCell>
                <BodyCell align={"left"}>{row.email}</BodyCell>
                <BodyCell align="center">{row.last_active}</BodyCell>
                <BodyCell align={"center"}
                          sx={{width: '20px', padding: '0px', margin: '0px'}}
                >
                    {row.is_active ? <CheckMark color={"primary"}/> : null}
                </BodyCell>
            </StyledTableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        primary_company: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        last_active: PropTypes.string,
        is_active: PropTypes.bool,
    }).isRequired,
};


export default function UserTable({search, page, user}) {
    const [searchVal] = search;
    const [userVal, setUser] = user;
    const data = useState({count: 0, results: []});
    const [filter, setFilter] = React.useState("All");
    const [dataVal, setData] = data;
    const {loading} = useContext(SiteContext);
    const [loadingVal, setLoading] = loading;

    useEffect(() => {
        setLoading(false);
        GetUserList(searchVal, filter, setData, page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingVal, filter]);

    return (
        <StyledContainer component={Paper}>
            <Table stickyHeader aria-label="collapsible table">
                <TableHead>
                    <TableRow key={'headers'}>
                        <HeaderCell align={"left"}>User</HeaderCell>
                        <HeaderCell align={"left"}>Company</HeaderCell>
                        <HeaderCell align={"left"}>Email</HeaderCell>
                        <HeaderCell align={"center"} sx={{padding: 0}}>Last Active</HeaderCell>
                        <HeaderCell align={"center"} sx={{paddingRight: '10px'}}>
                            <FilterButton id={'filterBtn'} filter={filter} setFilter={setFilter}/>
                        </HeaderCell>
                    </TableRow>
                </TableHead>
                <StyledTableBody>
                    {dataVal && dataVal.results.map((row) => (
                        <Row key={row.id} row={row} user={[userVal, setUser]}/>
                    ))}
                </StyledTableBody>
            </Table>
        </StyledContainer>
    )
}