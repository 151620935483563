import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertBar({alertBar, setAlertBar}) {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertBar({...alertBar, open: false});
    };

    return (
            <Snackbar
                open={alertBar.open}
                autoHideDuration={alertBar.time}
                onClose={handleClose}
                anchorOrigin={{horizontal:'center',vertical:'bottom'}}
            >
                <Alert onClose={handleClose} severity={alertBar.alertType}>
                    {alertBar.alertMsg}
                </Alert>
            </Snackbar>
    );
}