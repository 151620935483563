import React, {useContext} from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from "@mui/material/Typography";
import {Container, Toolbar} from "@mui/material";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import InvoiceLinesTable from "./InvoiceLinesTable";
import {InvoicePageContext} from "./InvoiceContext";
import Glass from "../../components/Glass";
import PropTypes from "prop-types";

const StyledModal = styled(Modal)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '80vh',
    marginTop: '10vh',
    marginBottom: '10vh'
}))

const Logo = styled('img')(({theme}) => ({
    margin: 20, height: 50, width: 200,
}))
const StyledAppBar = styled(AppBar)(({theme}) => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderBottom: "5px solid black",
    background: "transparent",
    color: "black",
}))

function InvoiceModalHeader(props) {
    const {invoiceDetailsData} = useContext(InvoicePageContext);
    const [data,] = invoiceDetailsData;
    //Columns [[Left],[Right]]
    const cellWidth = [[3,9],[5,7]];

    if (!data || data.count === 0) {
        return (<Grid container spacing={1.25} id={'invoice-modal-header'}/>)
    }

    return <Grid container spacing={1.25} id={'invoice-modal-header'}>
        {/*Left Column*/}
        <Grid item md={8} sm={12}>
            {/*    Row 1*/}
            <Grid container md={12} sm={12}>
                <Grid item sm={cellWidth[0][0]}>
                    <Typography component={props.component} variant={props.component}>
                        Invoice #:
                    </Typography>
                </Grid>
                <Grid item sm={cellWidth[0][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.invoice}
                    </Typography>
                </Grid>
            </Grid>
            {/*    Row 2*/}
            <Grid container sm={12}>
                <Grid item sm={cellWidth[0][0]}>
                    <Typography component={props.component} variant={props.component}>
                        Invoice Date:
                    </Typography>
                </Grid>
                <Grid item sm={cellWidth[0][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.invoice_date}
                    </Typography>
                </Grid>
            </Grid>
            {/*    Row 3*/}
            <Grid container sm={12}>
                <Grid item sm={cellWidth[0][0]}>
                    <Typography component={props.component} variant={props.component}>
                        Terms:
                    </Typography>
                </Grid>
                <Grid item sm={cellWidth[0][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.terms}
                    </Typography>
                </Grid>
            </Grid>
            {/*    Row 4*/}
            <Grid container sm={12}>
                <Grid item sm={cellWidth[0][0]}>
                    <Typography component={props.component} variant={props.component}>
                        WorkOrder #:
                    </Typography>
                </Grid>
                <Grid item sm={cellWidth[0][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.work_order}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        {/*Right Column*/}
        <Grid item md={4} sm={12}>
            {/*    Row 1*/}
            <Grid container md={12} sm={12}>
                <Grid item sm={cellWidth[1][0]}>
                    <Typography component={props.component} variant={props.component}>
                        Paid:
                    </Typography>
                </Grid>
                <Grid item sm={cellWidth[1][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.paid}
                    </Typography>
                </Grid>
            </Grid>
            {/*    Row 2*/}
            <Grid container sm={12}>
                <Grid item sm={cellWidth[1][0]}>
                    <Typography component={props.component} variant={props.component}>
                        Customer PO#:
                    </Typography>
                </Grid>
                <Grid item sm={cellWidth[1][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.cust_po}
                    </Typography>
                </Grid>
            </Grid>
            {/*    Row 3*/}
            <Grid container sm={12}>
                <Grid item sm={cellWidth[1][0]}>
                    <Typography component={props.component} variant={props.component}>
                        Quote:
                    </Typography>
                </Grid>
                <Grid item sm={cellWidth[1][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.quote}
                    </Typography>
                </Grid>
            </Grid>
            {/*    Row 4*/}
            <Grid container sm={12}>
                <Grid item sm={cellWidth[1][0]}>
                    <Typography component={props.component} variant={props.component}>
                        Batch #:
                    </Typography>
                </Grid>
                <Grid item sm={cellWidth[1][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.batch}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

InvoiceModalHeader.propTypes = {
    data: PropTypes.shape({
        sales_person:PropTypes.string,
        quote:PropTypes.string,
        cust_po:PropTypes.string,
        work_order:PropTypes.string,
        invoice:PropTypes.string,
        invoice_date:PropTypes.string,
        terms:PropTypes.string,
        paid:PropTypes.string,
        batch:PropTypes.string
    }).isRequired,
};
// OrderModalHeader.propTypes = {component: PropTypes.string};

function InvoiceModalFooter(props) {
    const {invoiceDetailsData} = useContext(InvoicePageContext);
    const [data,] = invoiceDetailsData;

    if (!data || data.count === 0) {
        return (<Grid container spacing={1.25}/>)
    }

    return <Grid container spacing={1.25}>
        <Grid item md={8} sm={6} xs={0}></Grid>
        <Grid item md={2} sm={2} xs={2}>
            <Typography component={props.component} variant={props.component}>
                Sub-Total <br/>
                Freight <br/>
                Tax
            </Typography>
            <div style={{backgroundColor: "black", height: "2px"}}></div>
            <Typography component={props.component} variant={props.component}>
                Total
            </Typography>
        </Grid>
        <Grid item md={2} sm={2} xs={2} align={"right"} paddingRight={"45px"}>
            <Typography component={props.component} variant={props.component}>
                $ {data.sub_total} <br/>
                $ {data.freight} <br/>
                $ {data.tax}
            </Typography>
            <div style={{backgroundColor: "black", height: "2px"}}></div>
            <Typography component={props.component} variant={props.component}>
                $ {data.total}
            </Typography>
        </Grid>
    </Grid>;
}

InvoiceModalFooter.propTypes = {
    component: PropTypes.string,
    data: PropTypes.shape({
        sub_total:PropTypes.string,
        freight:PropTypes.string,
        tax:PropTypes.string,
        total:PropTypes.string,
    }),
};

export default function InvoiceModal({open, setOpen}) {
    const {invoiceDetailsData} = useContext(InvoicePageContext);
    const [dataVal,] = invoiceDetailsData;
    const text_size = "h6";

    return (<StyledModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {
            setOpen(false);
        }}
        closeAfterTransition
    >
        <Fade in={open}>
            <Container>
                <Glass>
                    <StyledAppBar>
                        <Logo src={"WCA-OEM-Outline.png"} alt={"WCA logo"}/>
                        <Typography component="h1" variant="h5" align={"right"}>
                            Invoice Information
                        </Typography>
                        {/*<StyledBtn onClick={()=>{window.print()}}>Print</StyledBtn>*/}
                    </StyledAppBar>
                    <Toolbar style={{paddingBottom: '100px'}}/>
                    <InvoiceModalHeader data={dataVal} component={text_size}/>
                    <InvoiceLinesTable />
                    <InvoiceModalFooter data={dataVal} component={text_size}/>
                    <p>*This is not an actual invoice. This page contains a snapshot of
                        partial order information and may not reflect all data or recent changes. </p>
                </Glass>
            </Container>
        </Fade>
    </StyledModal>);
}
