import {styled} from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const FilterBtn = styled(Button)(({theme}) => ({
    float: 'right',
    height: 20,
    // width: 15,
    margin: 5,
    right: 1,
    padding: 10,
}))


export default function FilterButton({filter,setFilter}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (arg) => {
        setAnchorEl(null);
        if (['all' ,'active' ,'inactive'].includes(arg)) {
            setFilter(arg);
        }
    };

    return (
        <div>
            <FilterBtn
                id={'filterBtn'}
                variant={"outlined"}
                aria-controls="demo-positioned-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                startIcon={<FilterListIcon/>}
            >
                {filter}
            </FilterBtn>
            <Menu
                id="filterBtnMenu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose("all")
                }}>All</MenuItem>
                <MenuItem onClick={() => {
                    handleClose("active")
                }}>Active</MenuItem>
                <MenuItem onClick={() => {
                    handleClose("inactive")
                }}>Inactive</MenuItem>
            </Menu>
        </div>
    );
}
