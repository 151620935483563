import React, {createContext, useState} from "react";

export const ManageUsersContext = createContext({});

export const ManageUsersProvider = props => {
    const [user, setUser] = useState();
    const [companies, setCompanies] = useState([]);
    return (
        <ManageUsersContext.Provider
            value={
                {
                    user: [user,setUser],
                    companies: [companies, setCompanies],
                }
            }>
            {props.children}
        </ManageUsersContext.Provider>
    );
};