import {styled} from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";


export const StyledTableRow = styled(TableRow)(({theme}) => ({
    margin: 0,
    padding: 0,
    height: 32,
    '& > *': {
        borderBottom: 'unset',
    },
    "&:hover": {
        background: '#F0F0F0',
    }
}))

export const StyledTableCell = styled(TableCell)(({theme}) => ({
    padding: 0,
    paddingTop: 5,
}))

export const TableRoot = styled(TableContainer)(({theme}) => ({
    //64px is the height of the search bar and 45px is the size of the pagination.
    maxHeight: `calc(100% - (64px + 45px) )`,
}))

export const StyledTableBody = styled(TableBody)(({theme}) => ({}))

export const CollapseCell = styled(TableCell)(({theme}) => ({
    width: 30,
    margin: 0,
    padding: 0
}))

export const DropDownCell = styled(TableCell)(({theme}) => ({
    width: 100,
    paddingRight: 10,
}))