import React, {useEffect, useState} from "react";
import CircleMeter from "../../components/CircleMeter";
import {GetInvSummary} from "../../utils/AxiosUtils";
import {styled} from "@mui/material/styles";
import Top5InvItems from "./Top5InvItems";
import {Grid, Typography} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";

const Root = styled('div')(({theme}) => ({
    position: "relative",
    padding: 0,
    margin: 20,
}))


export default function InvSummary() {
    const [invSummary, setInvSummary] = useState([]);

    useEffect(() => {
        GetInvSummary(setInvSummary);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Root>
            <Typography variant="h6" gutterBottom component="div" style={{padding:0, margin:0}}>
                Inventory Summary
            </Typography>
            <Grid container spacing={0} sx={{height: '100%'}}>
                <Grid item xs={12} md={12} lg={4}>
                    <Toolbar/>
                    <CircleMeter radius={150} percent={invSummary.avg_level} title={"Average Level"}/>
                </Grid>
                <Grid item xs={12} md={12} lg={8}>
                    <Typography variant={"h5"} component={"div"} align={'center'}
                                sx={{
                                    background: 'white',
                                    borderBottom: '1px solid black',
                                    borderTopRightRadius: 5,
                                    borderTopLeftRadius: 5
                                }}
                    >Lowest 5 Stocked Inventory Items</Typography>
                    <Top5InvItems/>
                </Grid>
            </Grid>
        </Root>
    )
}