import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LineMeter from "../../components/LineMeter";
import {InvPageContext} from "./InventoryContext";
import {
    CollapseCell,
    DropDownCell,
    StyledTableBody,
    StyledTableCell,
    StyledTableRow,
    TableRoot
} from "../../site components/StyledTable";


function Row(props) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <StyledTableRow key={row.id + '_top'} onClick={() => setOpen(!open)}>
                <CollapseCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </CollapseCell>
                <StyledTableCell component="th" scope="row">
                    {row.item_name}
                </StyledTableCell>
                <StyledTableCell align="left">{row.cust_part}</StyledTableCell>
                <StyledTableCell align="left">{row.description}</StyledTableCell>
                <StyledTableCell align="center" sx={{maxWidth: 35, minWidth: 35}}>{row.avail}</StyledTableCell>
                <StyledTableCell align="center" sx={{maxWidth: 35, minWidth: 35}}>
                    <LineMeter
                        curr={row.avail}
                        min={row.min_qty}
                        max={row.max_qty}
                    />
                </StyledTableCell>
            </StyledTableRow>
            <TableRow key={row.item_name + '_outer'}>
                <TableCell sx={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Details
                            </Typography>
                            <Table size="small" aria-label="details">
                                <TableHead>
                                    <TableRow key={row.item_name + '_headers'}>
                                        <DropDownCell align="right">Warehouse</DropDownCell>
                                        <DropDownCell align={"right"}>WIP</DropDownCell>
                                        <DropDownCell align={"right"}>FA</DropDownCell>
                                        <DropDownCell align={"right"}>PO</DropDownCell>
                                        <DropDownCell align={"right"}>REQ</DropDownCell>
                                        <DropDownCell align={"right"}>WOs</DropDownCell>
                                        <DropDownCell align="right">YTD</DropDownCell>
                                        <DropDownCell align="right">2-YTD</DropDownCell>
                                        <DropDownCell align="right">Min</DropDownCell>
                                        <DropDownCell align="right">Max</DropDownCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={row.item_name + '_inner'}>
                                        <DropDownCell  align="right">{row.warehouse}</DropDownCell>
                                        <DropDownCell align="right">{row.wip}</DropDownCell>
                                        <DropDownCell align="right">{row.fa}</DropDownCell>
                                        <DropDownCell align="right">{row.ord_qty}</DropDownCell>
                                        <DropDownCell align="right">{row.req_qty}</DropDownCell>
                                        <DropDownCell align="right">{row.wos}</DropDownCell>
                                        <DropDownCell align="right">{row.ytd}</DropDownCell>
                                        <DropDownCell align="right">{row.ytd2}</DropDownCell>
                                        <DropDownCell align="right">{row.min_qty}</DropDownCell>
                                        <DropDownCell align="right">{row.max_qty}</DropDownCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        item_name: PropTypes.string,
        cust_part: PropTypes.string,
        description: PropTypes.string,
        avail: PropTypes.number,
        warehouse: PropTypes.number,
        ytd: PropTypes.number,
        ytd2: PropTypes.number,
        min_qty: PropTypes.number,
        max_qty: PropTypes.number,
        wip: PropTypes.number,
        fa: PropTypes.number,
        ord_qty:PropTypes.number,
        req_qty:PropTypes.number,
        wos:PropTypes.number

    }).isRequired,
};

export default function InvTable() {
    const {data} = useContext(InvPageContext);
    const [dataVal,] = data;

    return (
        <TableRoot component={Paper}>
            <Table stickyHeader aria-label="collapsible table">
                <TableHead>
                    <TableRow key={'headers'}>
                        <CollapseCell/>
                        <StyledTableCell align="left">WCA #</StyledTableCell>
                        <StyledTableCell align="left">Part #</StyledTableCell>
                        <StyledTableCell align="left">Description</StyledTableCell>
                        <StyledTableCell align="center" style={{maxWidth: 35, minWidth: 35}}>Available</StyledTableCell>
                        <StyledTableCell align="center">Inventory<br/> Level</StyledTableCell>
                    </TableRow>
                </TableHead>
                <StyledTableBody>
                    {dataVal && dataVal.results.map((row) => (
                        <Row key={row.id} row={row}/>
                    ))}
                </StyledTableBody>
            </Table>
        </TableRoot>
    );
}
