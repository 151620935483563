import React, {useContext, useEffect} from "react";
import {SiteContext} from "./utils/SiteContext";
import LoginPage from "./pages/login/LoginPage";
import ClientPortal from "./pages/portal/ClientPortal";
import {GetLoggedIn} from "./utils/AxiosUtils";
import {Portal} from "@mui/material";
import AlertBar from "./components/AlertBar";


export default function App() {
    const {userInfo,alert} = useContext(SiteContext);
    const [userInfoVal, setUserinfo] = userInfo;
    const [alertBar,setAlertBar] = alert;

    //With an empty dependency array this will only run on mount.
    useEffect(() => {
        GetLoggedIn(setUserinfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={"App"}>
            {userInfoVal.loggedIn ? <ClientPortal/> : <LoginPage/>}
            <Portal>
                <AlertBar alertBar={alertBar} setAlertBar={setAlertBar}/>
            </Portal>
        </div>
    );
}

