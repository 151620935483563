import React, {useEffect} from "react";
import {styled} from "@mui/material/styles";
import {Grid, Typography} from "@mui/material";

const Root = styled('div')(({theme}) => ({
    position: "relative",
    padding: 0,
    margin: 20,
}))


export default function InfoBlock() {

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Root>
            <Typography variant="h6" gutterBottom component="div" style={{padding: 0, margin: 0}}>
                Welcome to the WCAOEM web portal.
            </Typography>
            <Grid container spacing={0} sx={{height: '100%'}}>
                <p>
                    The web portal is currently in closed beta.&nbsp;
                    Please bear with us while we tailor the site to better suit your needs.&nbsp;
                    Any requests, suggestions, or problems should be passed on to your sales representative.
                </p>
                <p>
                    The Inventory page provides insights into the current stock levels of various items.&nbsp;
                    A minimum and maximum stock level can be set by the sales representative on request.&nbsp;
                    The Work Orders page shows orders that are currently being processed while the Invoice page will show orders that have been completed and billed.
                </p>
                <p>
                    Please note this information is only updated several times per day and may not reflect immediate changes.
                </p>
            </Grid>
        </Root>
    )
}