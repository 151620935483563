import React, {useContext} from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from '@mui/icons-material/Dashboard';
import {SiteContext} from "../utils/SiteContext";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import {GetLogout} from "../utils/AxiosUtils";
import {styled, useTheme} from "@mui/material/styles";
import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {ListItemButton} from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

const Root = styled(Drawer)(({theme}) => ({
    position: 'relative',
    maxWidth: theme.sideBar.width,
    height:'100%',
    flexGrow: 1,
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    '& .MuiDrawer-paper': {
        top: theme.appBar.height,
        width: theme.sideBar.width,
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
    }
}))

const BottomList = styled(List)(({theme}) => ({
    //6 is the number of items in the list.
    //  marginTop: `calc(100vh - ${64 * 8}px)`
    marginTop:`calc(100vh - ${theme.appBar.height}px - ${48*4 + 56*5}px)`,
    padding:0

}))

export default function ClippedDrawer(props) {
    const {loading,userInfo,tab,alert} = useContext(SiteContext)
    const [, setLoading] =  loading;
    const [, setAlertBar] = alert;
    const [, setTab] = tab;
    const [userInfoVal, setUserInfo] = userInfo;

    const theme = useTheme();
    const IconColor = {color: theme.palette.primary.contrastText}
    const DividerHeight = {height: 5}

    return (
        <Root
            variant="persistent"
            open={true}
        >
            <List>
                <ListItemButton key={'Dashboard'} onClick={() => {
                    setTab({page:'Dashboard'});
                }}>
                    <ListItemIcon><HomeIcon sx={IconColor}/></ListItemIcon>
                    <ListItemText primary={'Dashboard'}/>
                </ListItemButton>
                <ListItemButton key={'Inventory'} onClick={() => {
                    setTab({page:'Inventory'});
                }}>
                    <ListItemIcon><InventoryIcon sx={IconColor}/> </ListItemIcon>
                    <ListItemText primary={'Inventory'}/>
                </ListItemButton>
                {/*<ListItem button key={'Replacement'} onClick={()=>{setTab('Replacement');}}>*/}
                {/*    <ListItemIcon><ReplacementIcon/></ListItemIcon>*/}
                {/*    <ListItemText primary={'Replacement'}/>*/}
                {/*</ListItem>*/}
                <ListItemButton key={'Orders'} onClick={()=>{setTab({page: 'Work Orders'});}}>
                    <ListItemIcon><ShoppingCartIcon sx={IconColor}/></ListItemIcon>
                    <ListItemText primary={'Work Orders'}/>
                </ListItemButton>
                <ListItemButton key={'Invoices'} onClick={()=>{setTab({page: 'Invoices'});}}>
                    <ListItemIcon><PaidOutlinedIcon sx={IconColor}/></ListItemIcon>
                    <ListItemText primary={'Invoices'}/>
                </ListItemButton>
            </List>
            <Divider sx={DividerHeight}/>
            <List>
                <ListItemButton key={"Custom Page"} onClick={() => {
                    setTab({page:'Custom Page'});
                }}>
                    <ListItemIcon><InboxIcon sx={IconColor}/></ListItemIcon>
                    <ListItemText primary={"Custom Page"}/>
                </ListItemButton>
            </List>
            <Divider sx={DividerHeight}/>

            {userInfoVal.staff ?
                <React.Fragment>
                    <List>
                        <ListItemButton key={'Manage Users'} onClick={() => {
                            setTab({page:'Manage Users'});
                        }}>
                            <ListItemIcon><PeopleIcon sx={IconColor}/> </ListItemIcon>
                            <ListItemText primary={'Manage Users'}/>
                        </ListItemButton>
                    </List>
                    <Divider sx={DividerHeight}/>
                </React.Fragment>
                : <ListItem sx={{height: '70px'}}/>}
            {/*Blank listItem for missing menu item*/}

            <BottomList>
                <Divider sx={DividerHeight}/>
                <ListItemButton key={"Account"} onClick={() => {
                    setTab({page:'Account'});
                }}>
                    <ListItemIcon><AccountCircleIcon sx={IconColor}/> </ListItemIcon>
                    <ListItemText primary={"Account"}/>
                </ListItemButton>
                <Divider sx={DividerHeight}/>
                <ListItemButton key={"Logout"} onClick={()=>{
                    GetLogout(setLoading,setUserInfo,setTab,setAlertBar)
                }}>
                    <ListItemIcon><LogoutIcon sx={IconColor}/> </ListItemIcon>
                    <ListItemText primary={"Logout"}/>
                </ListItemButton>
            </BottomList>
        </Root>
    );
}
