import Glass from "../../components/Glass";
import UserTable from "./UserTable";
import React, {useContext, useEffect, useState} from "react";
import {SiteContext} from "../../utils/SiteContext";
import Pagination from "../../components/Pagination";
import UserDetails from "./UserDetails";
import Grid from "@mui/material/Grid";
import SearchBar from "../../site components/SearchBar";
import UserCompanies from "./UserCompanies";
import SignUpModal from "../login/UserSignUp";
import {styled} from "@mui/material/styles";
import StyledButton from "../../components/StyledButton";
import {PageContentArea} from "../../site components/PageContentArea";
import {ManageUsersContext} from "./ManageUsersContext";
import {GetAllCompanies} from "../../utils/AxiosUtils";

const AddUserBtn = styled(StyledButton)(({theme}) => ({
    float: 'right',
    width: '25%',
    maxWidth: 100,
    marginRight: 15,
}))

export default function ManageUsersPage() {
    const {alert} = useContext(SiteContext);
    const {user, companies} = useContext(ManageUsersContext);
    const [userVal,setUser] = user;
    const [companiesVal,setCompanies] = companies;
    const [alertBar, setAlertBar] = alert;
    const [searchVal, setSearch] = useState('');
    const search = [searchVal, setSearch];
    const [page, setPage] = useState({pg_size: 20, page: 1, count: 0})
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        GetAllCompanies(setCompanies);
    },[])

    return (
        <PageContentArea>
            <Grid container spacing={2} sx={{height: '100%'}}>
                <Grid item xs={12} md={6} sx={{height: '100%'}}>
                    <Glass sx={{height: '100%'}}>
                        <SearchBar search={search} page={[page, setPage]} >
                            <AddUserBtn
                                id={'addUserBtn'}
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => setOpen(true)}
                            >Add User</AddUserBtn>
                        </SearchBar>
                        <UserTable search={search} page={[page, setPage]} user={user}/>
                        <Pagination page={[page, setPage]}/>
                    </Glass>
                </Grid>
                <Grid item xs={12} md={6} sx={{height: '100%'}}>
                    <Grid container spacing={0} sx={{height: '100%',}}>
                        <Grid item xs={12} sx={{height: '50%',minHeight:'370px', paddingBottom: '10px'}}>
                            <Glass>
                                <UserDetails user={user}/>
                            </Glass>
                        </Grid>
                        <Grid item xs={12} sx={{height: '50%',minHeight:'370px',paddingTop: '10px'}}>
                            <Glass>
                                <UserCompanies user={user}/>
                            </Glass>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SignUpModal open={open} setOpen={setOpen} alertBar={alertBar} setAlertBar={setAlertBar}/>
        </PageContentArea>
    )
}