import {GetDownload, GetData} from "../../utils/AxiosUtils";
import React, {useContext, useEffect, useState} from "react";
import {SiteContext} from "../../utils/SiteContext";
import Glass from "../../components/Glass";
import SearchBar from "../../site components/SearchBar";
import {styled} from "@mui/material/styles";
import StyledButton from "../../components/StyledButton";
import {InvoicePageContext} from "./InvoiceContext";
import Pagination from "../../components/Pagination";
import {PageContentArea} from "../../site components/PageContentArea";
import InvoiceModal from "./InvoiceModal";
import InvoiceTable from "./InvoiceTable";

const DownloadButton = styled(StyledButton)(({theme}) => ({
    float: "right",
    marginRight: 20
}))

export default function InvoicePage() {
    const {loading} = useContext(SiteContext);
    const [loadingVal, setLoading] = loading;

    const {search, page, data} = useContext(InvoicePageContext);
    const [, setData] = data;
    const [searchVal] = search;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setLoading(false);
        GetData("InvoiceList", searchVal, setData, page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingVal]);

    return (
        <PageContentArea>
            <Glass>
                <SearchBar search={search} page={page}>
                    <DownloadButton
                        id={'downloadBtn'}
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => {
                            GetDownload(searchVal,'InvoiceDownload')
                        }}
                    >Download</DownloadButton>
                </SearchBar>
                <InvoiceTable openModal={setOpen}/>
                <Pagination data={data} page={page}/>
            </Glass>
            <InvoiceModal open={open} setOpen={setOpen}/>
        </PageContentArea>
    )
}