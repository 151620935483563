import React, {useContext} from "react";
import {SiteContext} from "../utils/SiteContext";
import {styled} from "@mui/material/styles";
import StyledButton from "../components/StyledButton";
import StyledTextField from "../components/StyledTextField";


const Root = styled('div')(({theme}) => ({
    justifyContent: "center",
    alignContent: "center",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
}))

const SearchField = styled(StyledTextField)(({theme}) => ({
    height: 35,
    marginTop: 8,
    marginLeft: 10,
    width: "30%"
}))

const StyledBtn = styled(StyledButton)(({theme}) => ({
    width: '25%',
    maxWidth: 100,
}))

export default function SearchBar({children, search,page}) {
    const [searchVal, setSearch] = search;
    const {loading} = useContext(SiteContext);
    const [, setLoading] = loading;
    const [pageVal,setPage] = page;

    return (
        <Root>
            <SearchField
                id={'searchBar-input'}
                variant={'outlined'}
                size={'small'}
                placeholder={'Search'}
                value={searchVal}
                color={'primary'}
                onChange={
                    (e) => {
                        setSearch(e.target.value);
                    }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setPage({...pageVal,page:1});
                        setLoading(true);
                    }
                }}
            />
            <StyledBtn
                id={'searchBarBtn'}
                onClick={() => {
                    setPage({...pageVal,page:1});
                    setLoading(true)
                }}
            >Search
            </StyledBtn>
            <StyledBtn
                id={'allItemsBtn'}
                onClick={() => {
                    setSearch(''); //Clear the search text
                    setLoading(true);
                }}
            >All Items
            </StyledBtn>
            {children}
        </Root>
    )
}