import {styled} from "@mui/material/styles";
import StyledTextField from "../../components/StyledTextField";
import * as PropTypes from "prop-types";
import React from "react";

const DetailField = styled(StyledTextField)(({theme}) => ({}))

export function AboutField(props) {
    return <DetailField
        inputProps={{maxLength: 512}}
        id={"about"}
        value={props.userDetails.about ? props.userDetails.about : " "}
        label={"About"}
        multiline={true}
        minRows={2}
        maxRows={6}
        onChange={props.onChange}
        error={(props.formCheck.about && /* istanbul ignore next */
            props.formCheck.about !== "") || false}
        helperText={props.formCheck.about}
    />;
}

AboutField.propTypes = {
    userDetails: PropTypes.any, onChange: PropTypes.func, formCheck: PropTypes.any
};

export function PhoneField(props) {
    return <DetailField
        inputProps={{maxLength: 32}}
        id={"phone"}
        value={props.userDetails.phone ? props.userDetails.phone : " "}
        label={"Phone"}
        onChange={props.onChange}
        error={(props.formCheck.phone && props.formCheck.phone !== "") || false}
        helperText={props.formCheck.phone}
    />;
}

PhoneField.propTypes = {
    userDetails: PropTypes.any, onChange: PropTypes.func, formCheck: PropTypes.any
};

export function EmailField(props) {
    return <DetailField
        id={"email"}
        value={props.userDetails.email ? props.userDetails.email : " "}
        label={"Email"}
        onChange={props.onChange}
        error={(props.formCheck.email && props.formCheck.email !== "") || false}
        helperText={props.formCheck.email}
    />;
}

EmailField.propTypes = {
    userDetails: PropTypes.any,
    onChange: PropTypes.func,
    formCheck: PropTypes.any
};

export function LastNameField(props) {
    return <DetailField
        inputProps={{maxLength: 128}}
        id={"last_name"}
        value={props.userDetails.last_name ? props.userDetails.last_name : " "}
        label={"Last Name"}
        onChange={props.onChange}
        error={(props.formCheck.last_name && props.formCheck.last_name !== "") || false}
        // error={false}
        helperText={props.formCheck.last_name}
    />;
}

LastNameField.propTypes = {
    userDetails: PropTypes.any,
    onChange: PropTypes.func,
    formCheck: PropTypes.any
};

export function FirstNameField(props) {
    return <DetailField
        inputProps={{maxLength: 128}}
        id={"first_name"}
        label={"First Name"}
        value={props.userDetails.first_name ? props.userDetails.first_name : " "}
        onChange={props.onChange}
        error={(props.formCheck.first_name && props.formCheck.first_name !== "") || false}
        helperText={props.formCheck.first_name}
    />;
}

FirstNameField.propTypes = {
    userDetails: PropTypes.any,
    onChange: PropTypes.func,
    formCheck: PropTypes.any
};