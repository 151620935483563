
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";


const StyledBtn = styled(Button)(({theme}) => ({
    background: theme.palette.primary.light,
    color: theme.palette.secondary.contrastText,
    height: 35,
    paddingLeft: 10,
    paddingRight: 10,
    // border:"1px solid lightblue",
    margin:10,
    "&:hover": {
        background: theme.palette.secondary.main,
    },
    "&:active": {
        transform: "translate(0px,3px)",
    }
}))

export default function StyledButton(props){

    return (
        <StyledBtn
            variant={"contained"}
            color={"secondary"}
            {...props}
        >{props.children}
        </StyledBtn>
    )
}