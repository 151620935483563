import {styled} from "@mui/material/styles";
import {Card} from "@mui/material";

const GlassCard = styled(Card)(props => ({theme}) => ({
    position: "relative",
    padding: theme.spacing(2, 2, 1),
    margin: 0,
    height:'100%',
    background: "linear-gradient(105.06deg, rgba(255, 255, 255, 0.7) 0%, rgba(210, 210, 255, 0.3) 90%)",
    borderRadius: 25,
    border: "2px solid white",
    overflow: "auto",
    backdropFilter: 'blur(10px);',
}))

export default function Glass(props) {
    return (
        <GlassCard {...props}>
            {props.children}
        </GlassCard>
    )
}