import {Checkbox, ListItem, ListItemIcon, ListItemText, Paper} from "@mui/material";
import List from "@mui/material/List";
import {styled} from "@mui/material/styles";
import {useEffect} from "react";

const ListPaper = styled(Paper)(({theme}) => ({
    width: '100%',
    height: '90%',
    overflow: "auto",
    borderRadius: 5,
}))

const StyledListItem = styled(ListItem)(({theme}) => ({
    margin: 0,
    padding: 0,
}))

const StyledCheckBox = styled(ListItemIcon)(({theme}) => ({
    margin: 0,
    padding: 0,
    justifyContent: "center",
    minWidth: 40
}))

const ListText = styled(ListItemText)(({theme}) => ({
    margin: 0,
    padding: 0,
}))

export default function CompanyListBox({items, checkBoxes, props} ) {
    const [checked, setChecked] = checkBoxes;

    const handleToggle = (value) => () => {
        const currentIndex = checked.findIndex((i) => i.id === value.id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    useEffect(()=>{

    },[items]);

    if (!items || items.length <= 0) {
        return (
            <ListPaper {...props}>
                <List dense component="div" role="list"/>
            </ListPaper>
        )
    }

    return (
        <ListPaper {...props}>
            <List
                dense component="div" role="list">
                {items && items.map((value) => {
                    const labelId = value.id;

                    return (
                        <StyledListItem
                            data-cy={"CompanyListItem"}
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <StyledCheckBox>
                                <Checkbox
                                    data-cy={"CompanyLiCheckbox"}
                                    sx={{padding: 0, margin: 0}}
                                    checked={checked.findIndex((i) => i.id === value.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            </StyledCheckBox>
                            <ListText
                                id={labelId}
                                primary={value.short_name}
                                secondary={value.company_name}/>
                        </StyledListItem>
                    );
                })}
                <ListItem/>
            </List>
        </ListPaper>
    );
}