import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    TextField
} from "@mui/material";
import {styled} from "@mui/material/styles";
import React, {useContext, useEffect, useState} from 'react';
import {SiteContext} from "../../utils/SiteContext";
import {DeleteCompany, GetAllCompanies} from "../../utils/AxiosUtils";
import StyledButton from "../../components/StyledButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import {ManageUsersContext} from "./ManageUsersContext";

const DeleteCompanyBtn = styled(StyledButton)(({theme}) => ({
    float: 'right',
}))

const StyledTextField = styled(TextField)(({theme}) => ({
    padding: 0, margin: 10, width: '95%',
}))


export default function DeleteCompanyButton() {
    const [open, setOpen] = useState(false);
    const {loading, alert} = useContext(SiteContext);
    const {companies} = useContext(ManageUsersContext);
    const [, setLoading] = loading;
    const [,setAlert]= alert;
    const [currCompany,setCurrCompany] = useState();
    const [companiesVal, setCompanies] = companies;

    useEffect(() => {
        // GetAllCompanies(setCompanies);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companiesVal])

    const handleClickOpen = () => {
        setOpen(true);
        setCurrCompany();
    };

    const handleClose = () => {
        setOpen(false);
        setCurrCompany();
    };

    const handleSave = () => {
        setOpen(false);
        DeleteCompany(currCompany,setCompanies, setLoading, setAlert);
    }

    return (<React.Fragment>
        <DeleteCompanyBtn
            id={'deleteCompanyBtn'}
            variant={"contained"}
            color={"primary"}
            startIcon={<DeleteIcon/>}
            onClick={handleClickOpen}
        >Delete</DeleteCompanyBtn>
        <Dialog
            fullScreen={false}
            open={open}
            onClose={handleClose}
            aria-labelledby="delete-company-dialog"
        >
            <DialogTitle
                id="delete-company-dialog">
                {"Delete Company."}
            </DialogTitle>
            <DialogContent sx={{height: 'auto', width:'400px'}}>
                <FormControl fullWidth>
                    <Select
                        labelId='deleteCompanySelectContent'
                        id={'deleteCompanySelectContent'}
                        size={"small"}
                        value={currCompany ? currCompany : " "}
                        onChange={(e) => {
                            setCurrCompany(e.target.value);
                        }}
                    >
                        <MenuItem key={0} value={" "}></MenuItem>
                        {companiesVal.map((c) => (
                            <MenuItem key={c.id}
                                        value={c}
                            >{c.company_name} ({c.short_name})</MenuItem>

                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    id={'abortCompanyBtn'}
                    variant={'outlined'}
                    onClick={handleClose}>
                    Abort
                </Button>
                <Button
                    id={'deleteCompanyFormBtn'}
                    variant={'outlined'}
                    onClick={handleSave} autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>)
}