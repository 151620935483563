import {Tooltip} from "@mui/material";
import {styled} from "@mui/material/styles";

const Meter = styled('div')(props => ({theme}) => ({
    position: 'relative',
    display: 'inline-block',
    width:'90%',
    height:'20px',
    margin:0,
    padding:0,
    border: '2px solid black'
}))

const Fill = styled('div')(props => ({theme}) => ({
    width:`${props.percent}%`,
    height:'16px',
    margin:0,
    padding:0,
    background:`linear-gradient( to bottom, rgba(${props.color},.9) 70%, rgba(${props.color},0.4))`,
    "&:after":{
        content:'""',
        position:'absolute',
        top:'1px',
        left:'1px',
        width:`100%`,
        height:'50%',
        background: `linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.2))`
        // background:'red'
    }
}))

export default function LineMeter({curr, min, max}) {
    // Figure out what degree the arrow should be at. Degree 0 is straight up.
    let percent = ((curr - min) / (max - min)) *100
    if(isNaN(percent) || percent<2){
        percent = 2;
    }
    let color='255,0,0';
    if(percent > 30 && percent <= 70){
        // 'rgb(252,189,16)'
        color='250,200,0';
    }
    else if(percent>70){
        // 'rgba(55,157,1)'
        color='55,157,1';
    }
    if(percent>100){
        percent=100;
    }
    return (
        <Tooltip title={
            `Min(${min})  Max(${max})`
        } arrow>
            <Meter>
                <Fill percent={percent} color={color}>

                </Fill>
            </Meter>
        </Tooltip>
    )
}