import React, {useContext, useEffect} from "react";
import Grid from "@mui/material/Grid";
import {FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {AboutField, EmailField, FirstNameField, LastNameField, PhoneField} from "../login/LoginFields";
import {ManageUsersContext} from "./ManageUsersContext";


export default function UserDetailFields({userDetail, errorForm}) {
    const {companies} = useContext(ManageUsersContext);
    const [companiesVal,] = companies;
    const [userDetails, setUserDetails] = userDetail;
    const [formCheck] = errorForm;

    useEffect(() => {
        // GetAllCompanies(setCompanies);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companiesVal])
    return (<React.Fragment>
        <Grid container spacing={1.25}>
            <Grid item sm={12} md={6}>
                <FirstNameField userDetails={userDetails} onChange={(e) => {
                    setUserDetails({...userDetails, first_name: e.target.value});
                }} formCheck={formCheck}/>
            </Grid>
            <Grid item sm={12} md={6}>
                <LastNameField userDetails={userDetails} onChange={(e) => {
                    setUserDetails({...userDetails, last_name: e.target.value});
                }} formCheck={formCheck}/>
            </Grid>
            <Grid item xs={12}>
                <EmailField userDetails={userDetails} onChange={(e) => {
                    setUserDetails({...userDetails, email: e.target.value});
                }} formCheck={formCheck}/>
            </Grid>
            <Grid item xs={12} sm={8}>
                <FormControl fullWidth
                             error={(formCheck.primary_company && (formCheck.primary_company !== '')) || false}
                >
                    <InputLabel id="select-primary_company-label">Primary Company</InputLabel>
                    <Select
                        labelId="select-primary_company-label"
                        id={'primary_company'}
                        value={userDetails.primary_company ? userDetails.primary_company : " "}
                        label="Primary Company"
                        size={"small"}
                        onChange={(e) => {
                            setUserDetails({...userDetails, primary_company: e.target.value});
                        }}
                    >
                        <MenuItem key={0} value={" "}></MenuItem>
                        {companiesVal.map((c) => (<MenuItem key={c.id}
                                                            value={c.company_name}>{c.company_name} ({c.short_name})</MenuItem>))}
                    </Select>

                    <FormHelperText id={'primary_company-helper-text'}>{formCheck.primary_company}</FormHelperText>
                </FormControl>

            </Grid>
            <Grid item xs={12} sm={4}>
                <PhoneField userDetails={userDetails} onChange={(e) => {
                    setUserDetails({...userDetails, phone: e.target.value});
                }} formCheck={formCheck}/>
            </Grid>
            <Grid item xs={12}>
                <AboutField userDetails={userDetails}
                            onChange={(e) => {
                                setUserDetails({...userDetails, about: e.target.value});
                            }}
                            formCheck={formCheck}/>
            </Grid>
        </Grid>
    </React.Fragment>)
}