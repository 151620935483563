import {Tooltip} from "@mui/material";
import {styled} from "@mui/material/styles";


const Circle = styled('div')(props => ({theme}) => ({
    position: 'relative',
    display: 'inline-block',
    margin: 0,
    padding: 0,
    transform: "translate(0%, 0%)",
    height: props.radius,
    width: props.radius * 2,
    borderRadius: `${props.radius}px ${props.radius}px 0 0`,
    border: 'solid black 1px',
    background: "conic-gradient(from 0deg at 50% 99%, #FAFF08 -20.24deg, #FAFF07 16.87deg, #00BA07 89.59deg, #E30000 270.96deg, #FAFF08 339.76deg, #FAFF07 376.87deg);",
    "&:after": {
        content: '""',
        position: 'absolute',
        top:'2px',
        left: '7px',
        height: props.radius * .5,
        width: props.radius * 1.5 - 4,
        borderRadius: `50px 50px 10px 10px`,
        background: `linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.2))`
        // background:'red'
    }
}))

const Dial = styled('div')(props => ({theme}) => ({
    position: 'absolute',
    top: `calc(100% - ${props.radius * .2}px)`,
    left: "50%",
    transform: "translate(-50%, 0%)",
    height: props.radius * .2,
    width: props.radius * .4,
    borderRadius: `${props.radius * .2}px ${props.radius * .2}px 0 0`,
    background: "black",
}))

const Arrow = styled('div')(props => ({theme}) => ({
    position: 'absolute',
    top: -props.radius * .1 - 5,
    left: props.radius - 2,
    transformOrigin: "bottom",
    height: 0,
    width: 0,
    padding: 0,
    margin: 0,
    borderLeft: `${props.radius * .05}px solid transparent`,
    borderRight: `${props.radius * .05}px solid transparent`,
    borderBottom: `${props.radius + 5}px solid black`,
    transform: `translate(0, 0px) rotate(${props.degree}deg)`,
}))

export default function Gauge({min, max, curr, radius}) {
    // Figure out what degree the arrow should be at. Degree 0 is straight up.
    let percent = (curr - min) / (max - min)
    if(isNaN(percent)){
        percent=0;
    }
    let degree = percent * 180 - 90;
    // Clamp the degree range to prevent the needle clipping into the bottom of the gauge.
    degree = Math.max(-85, Math.min(degree, 85))
    if(!degree){degree=0;}
    return (
        <Tooltip title={
            `Min(${min})  Max(${max})`
        } arrow>
            <Circle radius={radius} degree={degree}>
                <Dial radius={radius} degree={degree}/>
                <Arrow radius={radius} degree={degree}/>
            </Circle>
        </Tooltip>
    )
}