import Glass from "../../components/Glass";
import {Toolbar, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {PageContentArea} from "../../site components/PageContentArea";

const StyledImage = styled('img')(({theme}) => ({
    position: "absolute",
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
}))

export default function CustomPage() {

    return (
        <PageContentArea>
            <Glass>
                <Toolbar sx={{justifyContent: "center"}}>
                    <Typography variant={'h4'}>Custom pages for your unique business needs.</Typography>
                </Toolbar>
                <div>
                    <StyledImage src={"undraw_custompage.svg"} alt={"undraw design"}/>
                </div>
            </Glass>
        </PageContentArea>
    )
}