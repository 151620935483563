import {styled} from "@mui/material/styles"
import Typography from "@mui/material/Typography";
import React from "react";


const Mark = styled('div')(props => ({theme}) => ({
    position: 'absolute',
    borderRadius: '20%',
    top: `calc(50% - ${props.radius * .5}px)`,
    left: `calc(50% - ${props.radius * .025 / 2}px)`,
    width: `${props.radius * .03}px`,
    height: `${props.radius * .5}px`,
    zIndex: 1,
    transformOrigin: `bottom center`,
    transform: `rotate(${props.ticks.deg}deg) `,
}))

const TickMark = styled('div')(props => ({theme}) => ({
    width: `${props.radius * .03}px`,
    height: `${props.radius * .1}px`,
    borderRadius: '20%',
    backgroundColor: `${props.ticks.color}`,
}))

const Meter = styled('div')(props => ({theme}) => ({
    position: 'relative',
    width: `${props.radius}px`,
    height: `${props.radius}px`,
    margin: 5,
    padding: 0
}))
const MeterBackground = styled('div')(props => ({theme}) => ({
    width: `${props.radius}px`,
    height: `${props.radius}px`,
    borderRadius: '50%',
    border: `${props.radius * .1}px solid rgba(255, 255, 255, .3)`,
    margin: 0,
    padding: 0
}))

const Percentage = styled(Typography)(props => ({theme}) => ({
    position: 'absolute',
    color: theme.palette.primary.main,
    top: `${props.radius / 2 - 20}px`,
    width: '100%',
    margin: 0,
    padding: 0
}))

const Label = styled(Typography)(props => ({theme}) => ({
    color: theme.palette.primary.main,
    width: `${props.radius}px`,
    height: '100%',
    margin: 0,
    padding: 0,

}))

const Root = styled('div')(props => ({theme}) => ({
    position: 'relative',
    display: 'inline-block',
    // background: '#DEADBEEF',
    width: `${props.radius}px`,
    height: props.radius,
    margin: 0,
    padding: 0
}))
export default function CircleMeter({radius, percent, title}) {
    let marks = []
    //https://colordesigner.io/gradient-generator
    // let colors = [
    //     '#ff0000',
    //     '#ff1d00',
    //     '#ff2c00',
    //     '#fe3800',
    //     '#fd4200',
    //     '#fc4b00',
    //     '#fb5300',
    //     '#fa5b00',
    //     '#f86200',
    //     '#f66900',
    //     '#f47000',
    //     '#f17600',
    //     '#ef7d00',
    //     '#ec8300',
    //     '#e98900',
    //     '#e68f00',
    //     '#e29400',
    //     '#df9a00',
    //     '#db9f00',
    //     '#d7a500',
    //     '#d3aa00',
    //     '#ceaf00',
    //     '#c9b400',
    //     '#c4b900',
    //     '#bfbe00',
    //     '#bac300',
    //     '#b4c800',
    //     '#aecc00',
    //     '#a7d100',
    //     '#a1d500',
    //     '#99da00',
    //     '#92de00',
    //     '#89e200',
    //     '#80e700',
    //     '#76eb00',
    //     '#6bef00',
    //     '#5df300',
    //     '#4df700',
    //     '#37fb09',
    //     '#07ff26',];
    let colors = [
        '#FF0000',
        '#FF1100',
        '#FF2200',
        '#FF3300',
        '#FF4400',
        '#FF5500',
        '#FF6600',
        '#FF7700',
        '#FF8800',
        '#FF9900',
        '#FFAA00',
        '#FFBB00',
        '#FFCC00',
        '#FFDD00',
        '#FFEE00',
        '#FFFF00',
        '#EEFF00',
        '#DDFF00',
        '#CCFF00',
        '#BBFF00',
        '#AAFF00',
        '#99FF00',
        '#88FF00',
        '#77FF00',
        '#66FF00',
        '#55FF00',
        '#44e600',
        '#21c701',
        '#18c800',
        '#0cc800',
        '#00c800'];
    if (percent > 100) {
        percent = 100
    } else if (percent < 0) {
        percent = 0
    }

    for (let i = 0; i < colors.length * (percent / 100); i++) {
        let deg = (360 / (colors.length + 1)) * i;
        marks.push({deg: deg + 90 + (360 / (colors.length + 1)), color: colors[i]})
    }
    return (
        <Root>
            <Meter radius={radius}>
                <MeterBackground radius={radius}/>
                {marks.map((el) => (
                    <Mark key={"M" + el.deg} ticks={el} radius={radius}>
                        <TickMark key={"TM" + el.deg} ticks={el} radius={radius}/>
                    </Mark>
                ))}
                <Percentage
                    variant="h4"
                    align={"center"}
                    radius={radius}>
                    {percent}%
                </Percentage>
            </Meter>
            <Label variant={"h5"}
                   color={"primary"}
                   align={"center"}>
                {title}
            </Label>
        </Root>


    )
}