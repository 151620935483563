import React, {createContext, useState} from "react";

export const SiteContext = createContext({});

export const SiteProvider = props => {
    const [loading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState({loggedIn: false, name: "", staff: false, id:"",company:""});
    const [custCompany,setCustComapny] = useState()
    const [tab, setTab] = useState({page:'Dashboard',props:""})
    const [alertBar, setAlertBar] = useState({
        open: false,
        alertType: "info",
        alertMsg: "",
        time: 60
    });


    return (
        <SiteContext.Provider
            value={
                {
                    loading: [loading, setLoading],
                    userInfo: [userInfo, setUserInfo],
                    tab: [tab, setTab],
                    alert: [alertBar, setAlertBar],
                    custCompany: [custCompany,setCustComapny]
                }
            }>
            {props.children}
        </SiteContext.Provider>
    );
};