import React, {useContext, useState} from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {Container} from "@mui/material";
import {PostSignUp} from "../../utils/AxiosUtils";
import {SiteContext} from "../../utils/SiteContext";
import {styled} from "@mui/material/styles";
import StyledButton from "../../components/StyledButton";


const StyledModal = styled(Modal)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const TintedGlass = styled('div')(({theme}) => ({
    background: "linear-gradient(105.06deg, rgba(255, 255, 255, 0.7) 0%, rgba(210, 210, 255, 0.3) 90%)",
    border: "1px solid white",
    borderRadius: 25,
    padding: theme.spacing(2, 4, 3),
    backdropFilter: 'blur(10px);'
}))

const StyledForm = styled("form")(({theme}) => ({
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
}))

const SubmitButton = styled(StyledButton)(({theme}) => ({
    margin: theme.spacing(3, 0, 2),
}))


export default function SignUpModal({open, setOpen, setAlertBar}) {
    let [formData, setForm] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        primary_company: '',
        email: '',
        password: '',
        confirmPassword: '',
    })
    let [formCheck, setFormCheck] = useState({})
    const {loading} = useContext(SiteContext);
    const [, setLoading] = loading;
    const signup = (e) => {
        e.preventDefault()
        if (formData.password !== formData.confirmPassword) {
            let alert = {
                open: true,
                alertType: "error",
                alertMsg: "Passwords dont match."
            }
            setAlertBar(alert);
            return
        }

        let form = {
            email: "",
            password: "",
            firstname: "",
            lastname: "",
            phone: "",
            primary_company: ""
        }
        PostSignUp(formData, setFormCheck, form, setOpen, setAlertBar, setLoading);
    }

    return (
        <StyledModal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            closeAfterTransition
        >
            <Fade in={open}>
                <Container maxWidth={'xs'}>
                    <TintedGlass>
                        <Typography component="h1" variant="h5">
                            Create new account
                        </Typography>
                        <StyledForm onSubmit={signup}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="fName"
                                label="First Name"
                                name="first name"
                                autoFocus
                                onChange={(e) => {
                                    setForm({...formData, firstname: e.target.value});
                                }}
                                error={formCheck.firstname && formCheck.firstname !== ''}
                                helperText={formCheck.firstname}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="lName"
                                label="Last Name"
                                name="last name"
                                onChange={(e) => {
                                    setForm({...formData, lastname: e.target.value});
                                }}
                                error={formCheck.lastname && formCheck.lastname !== ''}
                                helperText={formCheck.lastname}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="company"
                                label="Company"
                                name="company"
                                onChange={(e) => {
                                    setForm({...formData, primary_company: e.target.value});
                                }}
                                error={formCheck.primary_company && formCheck.primary_company !== ''}
                                helperText={formCheck.primary_company}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="signup email"
                                autoComplete="email"
                                onChange={(e) => {
                                    setForm({...formData, email: e.target.value});
                                }}
                                error={formCheck.email && formCheck.email !== ''}
                                helperText={formCheck.email}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="phone"
                                label="Phone Number"
                                name="phone"
                                autoComplete="phone"
                                onChange={(e) => {
                                    setForm({...formData, phone: e.target.value});
                                }}
                                error={formCheck.phone && formCheck.phone !== ''}
                                helperText={formCheck.phone}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="signup password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete={"password"}
                                onChange={(e) => {
                                    setForm({...formData, password: e.target.value});
                                }}
                                error={formCheck.password && formCheck.password !== ''}
                                helperText={formCheck.password}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                autoComplete={"password"}
                                onChange={(e) => {
                                    setForm({...formData, confirmPassword: e.target.value});
                                }}
                                error={formCheck.password && formCheck.password !== ''}
                                helperText={formCheck.password}
                            />
                            <SubmitButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                name={'signup submit'}
                            >
                                Submit
                            </SubmitButton>
                        </StyledForm>
                    </TintedGlass>
                </Container>
            </Fade>
        </StyledModal>
    );
}
