import Login from './Login.jsx'
import React, {useContext, useState} from "react";
import SignUpModal from "./UserSignUp";
import {SiteContext} from "../../utils/SiteContext";
import Glass from "../../components/Glass";
import {styled} from "@mui/material/styles";


const Root = styled('div')(({theme}) => ({
    height: "75vh",
    width: '60vw',
    transform: 'translate(0,10vh)'
}))

export default function LoginPage() {
    const {alert} = useContext(SiteContext);
    const [alertBar, setAlertBar] = alert;
    const [open, setOpen] = useState(false);

    return (
        <Root>
            <Glass>
                <Login open={open} setOpen={setOpen}/>
                <SignUpModal open={open} setOpen={setOpen} alertBar={alertBar} setAlertBar={setAlertBar}/>
            </Glass>
        </Root>
    );
}



