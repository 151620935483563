import SyncIcon from "@mui/icons-material/Sync";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";


const StyledButton = styled(Button)(({theme}) => ({
    margin: 0,
    padding: 0,
    minWidth: 0,
    position: "absolute",
    right: 0,
    top: 0
}))

export default function RefreshButton(props)
{
 return(
     <StyledButton
        variant={"contained"}
        color={"secondary"}
        {...props}
        ><SyncIcon/>
     </StyledButton>
    )
}