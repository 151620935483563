import {GetDownload, GetData} from "../../utils/AxiosUtils";
import React, {useContext, useEffect} from "react";
import {SiteContext} from "../../utils/SiteContext";
import Pagination from "../../components/Pagination";
import Glass from "../../components/Glass";
import InvTable from "./InvTable";
import SearchBar from "../../site components/SearchBar";
import {styled} from "@mui/material/styles";
import StyledButton from "../../components/StyledButton";
import {InvPageContext} from "./InventoryContext";
import {PageContentArea} from "../../site components/PageContentArea";

const DownloadButton = styled(StyledButton)(({theme}) => ({
    float: "right",
    marginRight: 20
}))

export default function InventoryPage() {
    const {loading } = useContext(SiteContext);
    const [loadingVal, setLoading] = loading;

    const {data,search,page} = useContext(InvPageContext);
    const [, setData] = data;
    const [searchVal,] = search;

    useEffect(() => {
        setLoading(false);
        GetData('InvAllList',searchVal, setData, page);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingVal]);

    return (
        <PageContentArea>
            <Glass>
                <SearchBar search={search} page={page}>
                    <DownloadButton
                        id={'downloadBtn'}
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => {
                            GetDownload(searchVal,'InvDownload')
                        }}
                    >Download</DownloadButton>
                </SearchBar>
                <InvTable/>
                <Pagination data={data} page={page}/>
            </Glass>
        </PageContentArea>
    )
}