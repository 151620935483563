import {FormControlLabel, Switch, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {GetUserDetails, PatchUserDetails} from "../../utils/AxiosUtils";
import SaveIcon from '@mui/icons-material/Save';
import {SiteContext} from "../../utils/SiteContext";
import {styled} from "@mui/material/styles";
import DeleteUserButton from "./DeleteUserButton";
import UserDetailFields from "./UserDetailFields";
import RefreshButton from "../../components/RefreshButton";
import StyledButton from "../../components/StyledButton";

const Root = styled('div')(({theme}) => ({
    position: "relative",
    padding: 0,
    margin: 20,
    width: 'auto',
}))

const SaveButton = styled(StyledButton)(({theme}) => ({
    float: 'right',
}))

const blankUser = (setUserDetails) => {
    setUserDetails({
        id: -1,
        first_name: " ",
        last_name: " ",
        primary_company: " ",
        email: " ",
        phone: " ",
        about: " ",
        password: "testtest",
        is_active: false
    });
}

export default function UserDetails({user}) {
    const {alert, loading} = useContext(SiteContext);
    const [, setAlertBar] = alert;
    const [loadingVal, setLoading] = loading;
    const [userVal, setUser] = user;
    const [userDetails, setUserDetails] = useState({});
    let [errorForm, setErrorForm] = useState({
        firstname: "",
        lastname: "",
        phone: "",
        primary_company: "",
        email: "",
        password: ""
    })

    const getUserDetails = () => {
        if (!userVal) {
            blankUser(setUserDetails);
            return;
        }
        setErrorForm({});
        GetUserDetails(userVal, setUserDetails);
    }

    useEffect(() => {
        setErrorForm({});
        getUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userVal, loadingVal])

    const handleSave = () => {
        setErrorForm({});
        PatchUserDetails(userVal, userDetails, setAlertBar, setLoading, setErrorForm);
    }

    if (!userDetails) {
        /* istanbul ignore next */
        return (<Root/>)
    }

    return (
        <Root id={'userDetailsDiv'}>
            <Typography variant="h6" gutterBottom component="div">
                User Details
            </Typography>
            <RefreshButton
                id={'userDetailRefreshBtn'}
                onClick={getUserDetails}/>
            <UserDetailFields userDetail={[userDetails, setUserDetails]} errorForm={[errorForm,setErrorForm]}/>
            <FormControlLabel
                labelPlacement={'start'}
                control={
                    <Switch
                        checked={userDetails.is_active ? userDetails.is_active : false}
                        onChange={(e) => {
                            setUserDetails({...userDetails, is_active: e.target.checked});
                        }}
                        color='primary'
                        name="active"
                    />
                } label={'Account Activated'}/>
            <FormControlLabel
                labelPlacement={'start'}
                control={
                    <Switch
                        checked={userDetails.is_staff ? userDetails.is_staff : false}
                        onChange={(e) => {
                            setUserDetails({...userDetails, is_staff: e.target.checked});
                        }}
                        color='primary'
                        name="active"
                    />
                } label={'WCA Staff'}/>
            <SaveButton
                id={'userDetailSaveBtn'}
                variant={"contained"}
                color={"primary"}
                startIcon={<SaveIcon/>}
                onClick={handleSave}
            >Update Details</SaveButton>
            <DeleteUserButton
                id={'userDetailDeleteBtn'}
                val={userVal}
                setVal={setUser}
                details={userDetails.email}/>
        </Root>
    )
}