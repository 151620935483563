import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {InvoicePageContext} from "./InvoiceContext";
import {StyledTableCell, StyledTableRow, TableRoot} from "../../site components/StyledTable";


function Row(props) {
    const {row} = props;
    return (
        <React.Fragment>
            <StyledTableRow key={row.id}>
                <StyledTableCell align="left" style={{paddingLeft: 35}}>{row.item}</StyledTableCell>
                <StyledTableCell align="left">{row.desc}</StyledTableCell>
                <StyledTableCell align="left">{row.ref}</StyledTableCell>
                <StyledTableCell align="right">{row.qty}</StyledTableCell>
                <StyledTableCell align="right">$ {row.price.toFixed(2)}</StyledTableCell>
                <StyledTableCell align="right" style={{paddingRight: 30}}>$ {(row.qty*row.price).toFixed(2)}</StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        item:PropTypes.string,
        desc:PropTypes.string,
        qty:PropTypes.number,
        price:PropTypes.number,
        ref:PropTypes.string
    }).isRequired,
};

export default function InvoiceLinesTable() {
    const {invoiceData} = useContext(InvoicePageContext);
    const [dataVal,] = invoiceData;

    return (
        <TableRoot component={Paper}
                   style={{ maxHeight: `40vh`, marginBottom:10, marginTop:10}}
                    id={'invoice-lines-table'}
        >
            <Table stickyHeader aria-label="invoice lines table">
                <TableHead>
                    <TableRow key={'headers'}>
                        <StyledTableCell align="left" style={{paddingLeft: 35, width:'15%'}}>Item #</StyledTableCell>
                        <StyledTableCell align="left"  style={{ width:'45%'}}>Description</StyledTableCell>
                        <StyledTableCell align={"left"} style={{width:'5%'}}>Ref</StyledTableCell>
                        <StyledTableCell align="right" style={{ width:'10%'}}>Qty</StyledTableCell>
                        <StyledTableCell align="right" style={{ width:'10%'}}>Price</StyledTableCell>
                        <StyledTableCell align="right" style={{paddingRight: 30,width:'10%'}}>Amount</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataVal && dataVal.results.map((row) => (
                        <Row key={row.id} row={row}/>
                    ))}
                </TableBody>
            </Table>
        </TableRoot>
    );
}