import React, {createContext, useState} from "react";

export const InvPageContext = createContext({});

export const InventoryPageProvider = props => {
    const [data, setData] = useState({count: 0, results: []});
    const [formData, setFormData] = useState({so: "", sDate: "", eDate: ""});
    const [searchVal, setSearch] = useState('');
    const [pageVal,setPage] = useState({page: 1, pg_size: 20, count: 0});

    return (
        <InvPageContext.Provider
            value={
                {
                    data: [data, setData],
                    form: [formData, setFormData],
                    search: [searchVal,setSearch],
                    page: [pageVal,setPage]
                }
            }>
            {props.children}
        </InvPageContext.Provider>
    );
};