import React, {useContext, useState} from "react";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import StyledTextField from "../../components/StyledTextField";
import {Typography} from "@mui/material";
import StyledButton from "../../components/StyledButton";
import RefreshButton from "../../components/RefreshButton";
import {GetUserDetails, PatchUserDetails} from "../../utils/AxiosUtils";
import {SiteContext} from "../../utils/SiteContext";
import {AboutField, FirstNameField, LastNameField, PhoneField} from "../login/LoginFields";

const DetailField = styled(StyledTextField)(({theme}) => ({
    margin: 0, marginLeft: 10, marginRight: 10, padding: 0, width: `calc(100% - 20px)`,
}))

const Root = styled('div')(({theme}) => ({
    padding: 0, margin: 10,

}))

export default function AccountDetails({userDetail}) {
    const {alert, loading, userInfo} = useContext(SiteContext);
    const [, setAlertBar] = alert;
    const [, setLoading] = loading;
    const [userDetails, setUserDetails] = userDetail;
    const [user,] = userInfo;
    let [formCheck, setFormCheck] = useState({
        first_name: "", last_name: "", phone: "", primary_company: "", email: "", password: "",
    })

    const handleSave = () => {
        setFormCheck({});
        PatchUserDetails(user.id, userDetails, setAlertBar, setLoading, setFormCheck);
    }

    return (<Root name={'userDetails'}>
        <Typography variant={'h6'} sx={{margin: '10px'}}>
            Account Details
        </Typography>
        <RefreshButton
            name={"refreshDetailsBtn"}
            sx={{marginTop: '15px', marginRight: '20px'}}
            onClick={() => {
                GetUserDetails(user.id, setUserDetails);

                setFormCheck({
                    first_name: "", last_name: "", phone: "", primary_company: "", email: "", password: "",
                })
            }}
        />
        <Grid container spacing={1.25}>
            <Grid item xs={12}>
                <DetailField
                    disabled
                    id={"email"}
                    value={userDetails.email ? userDetails.email : " "}
                    label={"Email"}
                    /* istanbul ignore next */ // Changing email is disabled and should never generate an error
                    error={/* istanbul ignore next */ (formCheck.email && formCheck.email !== "") || /* istanbul ignore next */  false}
                    helperText={formCheck.email}
                    style={{margin:0, width:'100%'}}
                />
            </Grid>
            <Grid item xs={12}>
                <DetailField
                    disabled
                    inputProps={{maxLength: 128}}
                    id={'primary_company'}
                    value={userDetails.primary_company ? userDetails.primary_company : " "}
                    label={"Company"}
                    error={(formCheck.primary_company && /* istanbul ignore next */  formCheck.primary_company !== '') || false}
                    helperText={formCheck.primary_company}
                    style={{margin:0, width:'100%'}}
                />
            </Grid>
            <Grid item sm={12}>
                <FirstNameField userDetails={userDetails} onChange={(e) => {
                    setUserDetails({...userDetails, first_name: e.target.value});
                }} formCheck={formCheck}/>
            </Grid>
            <Grid item sm={12}>
                <LastNameField userDetails={userDetails} onChange={(e) => {
                    setUserDetails({...userDetails, last_name: e.target.value});
                }} formCheck={formCheck}/>
            </Grid>
            <Grid item xs={12}>
                <PhoneField userDetails={userDetails} onChange={(e) => {
                    setUserDetails({...userDetails, phone: e.target.value});
                }} formCheck={formCheck}/>
            </Grid>
            <Grid item xs={12}>
                <AboutField userDetails={userDetails}
                            onChange={(e) => {
                                setUserDetails({...userDetails, about: e.target.value});
                            }}
                            formCheck={formCheck}/>
            </Grid>
        </Grid>
        <StyledButton name={'saveDetailsBtn'} onClick={handleSave}>Save Details</StyledButton>
    </Root>)
}