import React, {useContext} from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from "@mui/material/Typography";
import {Container, Toolbar} from "@mui/material";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import WorkOrderLinesTable from "./WorkOrderLinesTable";
import {OrdersPageContext} from "./WorkOrdersContext";
import Glass from "../../components/Glass";
import PropTypes from "prop-types";

const StyledModal = styled(Modal)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '80vh',
    marginTop: '10vh',
    marginBottom: '10vh'
}))

const Logo = styled('img')(({theme}) => ({
    margin: 20, height: 50, width: 200,
}))
const StyledAppBar = styled(AppBar)(({theme}) => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderBottom: "5px solid black",
    background: "transparent",
    color: "black",
}))

function OrderModalHeader(props) {
    const {orderDetailsData} = useContext(OrdersPageContext);
    const [data,] = orderDetailsData;
    const cellWidth = [[3,9],[5,7]];
    if (!data || data.count === 0) {
        return (<Grid container spacing={1.25} id={'workorder-modal-header'}/>)
    }

    return <Grid container spacing={1.25} id={'workorder-modal-header'}>
        {/*Left Column*/}
        <Grid item md={8} sm={12}>
        {/*    Row 1*/}
            <Grid container md={12} sm={12}>
                <Grid item sm={cellWidth[0][0]}>
                    <Typography component={props.component} variant={props.component}>
                    WorkOrder #:
                    </Typography>
                </Grid>
                <Grid item sm={cellWidth[0][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.work_order}
                    </Typography>
                </Grid>
            </Grid>
        {/*    Row 2*/}
            <Grid container sm={12}>
                <Grid item sm={cellWidth[0][0]}>
                    <Typography component={props.component} variant={props.component}>
                        Date:
                    </Typography>
                </Grid>
                <Grid item sm={cellWidth[0][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.wo_date}
                    </Typography>
                </Grid>
            </Grid>
        {/*    Row 3*/}
            <Grid container sm={12}>
                <Grid item sm={cellWidth[0][0]}>
                    <Typography component={props.component} variant={props.component}>
                       Status:
                    </Typography>
                </Grid>
                <Grid item sm={cellWidth[0][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.status}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        {/*Right Column*/}
        <Grid item md={4} sm={12}>
            {/*    Row 1*/}
            <Grid container md={12} sm={12}>
                <Grid item sm={cellWidth[1][0]}>
                    <Typography component={props.component} variant={props.component}>
                        Customer PO#:
                    </Typography>
                </Grid>
                <Grid item  sm={cellWidth[1][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.cust_po}
                    </Typography>
                </Grid>
            </Grid>
            {/*    Row 2*/}
            <Grid container sm={12}>
                <Grid item sm={cellWidth[1][0]}>
                    <Typography component={props.component} variant={props.component}>
                       Terms:
                    </Typography>
                </Grid>
                <Grid item  sm={cellWidth[1][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.terms}
                    </Typography>
                </Grid>
            </Grid>
            {/*    Row 3*/}
            <Grid container sm={12}>
                <Grid item sm={cellWidth[1][0]}>
                    <Typography component={props.component} variant={props.component}>
                        Ship To:
                    </Typography>
                </Grid>
                <Grid item  sm={cellWidth[1][1]}>
                    <Typography component={props.component} variant={props.component}>
                        {data.ship_to}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}
OrderModalHeader.propTypes = {
    data: PropTypes.shape({
        sales_person:PropTypes.string,
        cust_po:PropTypes.string,
        ship_to:PropTypes.string,
        work_order:PropTypes.string,
        wo_date:PropTypes.string,
        terms:PropTypes.string,
        status:PropTypes.string,
    }).isRequired,
};
// OrderModalHeader.propTypes = {component: PropTypes.string};

export default function WorkOrderModal({open, setOpen}) {
    const {orderDetailsData} = useContext(OrdersPageContext);
    const [dataVal,] = orderDetailsData;
    const text_size = "h6";

    return (<StyledModal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            id={'workorder-modal'}
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            closeAfterTransition
        >
            <Fade in={open}>
                <Container>
                    <Glass>
                        <StyledAppBar>
                            <Logo src={"WCA-OEM-Outline.png"} alt={"WCA logo"}/>
                            <Typography component="h1" variant="h5" align={"right"}>
                                Order Information
                            </Typography>
                            {/*<StyledBtn onClick={()=>{window.print()}}>Print</StyledBtn>*/}
                        </StyledAppBar>
                        <Toolbar style={{paddingBottom: '100px'}}/>
                        <OrderModalHeader data={dataVal} component={text_size} />
                        <WorkOrderLinesTable />
                    </Glass>
                </Container>
            </Fade>
        </StyledModal>);
}
