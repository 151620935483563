import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {styled} from "@mui/material/styles";
import React, {useContext, useState} from 'react';
import {SiteContext} from "../../utils/SiteContext";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import {GetAllCompanies, PostCompanyList} from "../../utils/AxiosUtils";
import StyledButton from "../../components/StyledButton";
import {ManageUsersContext} from "./ManageUsersContext";

const AddCompanyBtn = styled(StyledButton)(({theme}) => ({
    float: 'right',
}))

const StyledTextField = styled(TextField)(({theme}) => ({
    padding: 0, margin: 10, width: '95%',
}))

const StyledImageInput = styled('input')(({theme})=>({

}))
const StyledForm = styled("form")(({theme}) => ({
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
}))
export default function AddCompanyButton() {
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState({fullname: "", shortname: ""})
    const [formErrors, setFormErrors] = useState({fullname:"",shortname:"",logo:""})
    const {loading, alert} = useContext(SiteContext);
    const {companies} = useContext(ManageUsersContext);
    const [comapniesVal,setCompanies] = companies;
    const [, setLoading] = loading;
    const [, setAlertBar] = alert;

    const handleClickOpen = () => {
        setOpen(true);
        setForm({fullname: "", shortname: "",logo:null});
        setFormErrors({});
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = (e) => {
        e.preventDefault();
        setFormErrors({});
        if (!form) {
            return;
        }
        PostCompanyList(form, setFormErrors, setLoading, setAlertBar, setCompanies)
            .then((result)=>{
                if(result){
                    setOpen(false);
                    setLoading(true);
                }
            })

    }

    return (<React.Fragment>
            <AddCompanyBtn
                id={'addCompanyBtn'}
                variant={"contained"}
                color={"primary"}
                startIcon={<AddBusinessIcon/>}
                onClick={handleClickOpen}
            >Add</AddCompanyBtn>
            <Dialog
                fullScreen={false}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <StyledForm onSubmit={handleSave}>
                <DialogTitle id="responsive-dialog-title">
                    {"Add Company."}
                </DialogTitle>
                <DialogContent sx={{height: 'auto'}}>
                    <StyledTextField
                        inputProps={{maxLength: 128}}
                        id={'full_name'}
                        variant={'outlined'}
                        size={'small'}
                        color={'primary'}
                        label={"Full company name."}
                        value={form.fullname ? form.fullname : ""}
                        onChange={(e) => {
                            setForm({...form, fullname: e.target.value})
                        }}
                        error={formErrors.company_name && formErrors.company_name !== ''}
                        helperText={formErrors.company_name}
                        required
                    />
                    <StyledTextField
                        inputProps={{maxLength: 5}}
                        id={'short_name'}
                        variant={'outlined'}
                        size={'small'}
                        color={'primary'}
                        label={"Short company name."}
                        value={form.shortname ? form.shortname : ""}
                        onChange={(e) => {
                            setForm({...form, shortname: e.target.value})
                        }}
                        error={formErrors.short_name && formErrors.short_name !== ''}
                        helperText={formErrors.short_name}
                        required
                    />
                    <p>Select company logo with max height of 72px, max width of 300px, and .PNG file type.</p>
                    <StyledImageInput
                        accept={"image/png"}
                        id={"post-logo"}
                        // value={form.logo ? form.logo : ""}
                        onChange={(e)=>{
                            setForm({...form, logo: e.target.files})
                        }}
                        name={"image"}
                        type={"file"}
                        error={formErrors.logo && formErrors.logo !== ''}
                        helperText={formErrors.logo}
                        required
                    />
                    <p style={{color:'Red'}}>{formErrors.logo}</p>
                </DialogContent>
                <DialogActions>
                    <Button
                        id={'abortBtn'}
                        variant={'outlined'}
                        onClick={handleClose}>
                        Abort
                    </Button>
                    <Button
                        type={'submit'}
                        id={'saveBtn'}
                        variant={'outlined'}
                        // onClick={handleSave} autoFocus
                        >
                        Save
                    </Button>
                </DialogActions>
                </StyledForm>
            </Dialog>
        </React.Fragment>)
}