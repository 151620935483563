import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from "@mui/material/Container";
import {SiteContext} from "../utils/SiteContext";
import {styled, useTheme} from "@mui/material/styles";

function ElevationScroll(props) {
    const {children} = props;

    return React.cloneElement(children, {
        elevation: useScrollTrigger() ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
};

const Root = styled(Container)(({theme}) => ({
    flexGrow: 1,
}))

const Logo = styled('img')(({theme}) => ({
    margin: 20,
    maxHeight: theme.appBar.height * .5,
    maxWidth: theme.appBar.height * 1.6,
}))

const StyledAppBar = styled(AppBar)(({theme}) => ({
    height: theme.appBar.height,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderBottom: "10px solid black",
    background: "white",
    color: "black",
}))

export default function TopAppBar(props) {
    const {userInfo, tab, custCompany} = useContext(SiteContext);
    const [userInfoVal,] = userInfo;
    const [tabVal,] = tab;
    const [custCompanyVal,] = custCompany;

    const theme = useTheme()
    const TextColor = {color: theme.palette.primary.main}
    return (
        <Root>
            <ElevationScroll {...props}>
                <StyledAppBar>
                    <Logo src={"WCA-OEM-Outline.png"} alt={"WCA logo"} sx={{}}/>
                    <Typography variant="h4" sx={TextColor} style={{width:'%80'}}>
                        <br/>
                        {tabVal.page}
                    </Typography>
                    <div>
                        <Logo src={custCompanyVal ? custCompanyVal.logo : "Blank.png"}
                              alt={"Logo"}
                              sx={{margin:0, marginTop:'20px'}}
                        />
                    <Typography variant="h6" sx={TextColor}>
                        Welcome, {userInfoVal.name}
                    </Typography>
                    </div>
                </StyledAppBar>
            </ElevationScroll>
            <Toolbar/>
        </Root>
    );
}
