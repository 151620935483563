import DeleteIcon from "@mui/icons-material/Delete";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {styled} from "@mui/material/styles";
import React, {useContext, useState} from 'react';
import {DeleteUser} from "../../utils/AxiosUtils";
import {SiteContext} from "../../utils/SiteContext";
import StyledButton from "../../components/StyledButton";


const DeleteBtn = styled(StyledButton)(({theme}) => ({
    float: 'right',
}))

export default function DeleteUserButton({val, setVal, details},props) {
    const [open, setOpen] = useState(false);
    const {loading} = useContext(SiteContext);
    const [, setLoading] = loading;

    const handleClickOpen = () => {
        if(!val){return;}
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        setOpen(false);
        DeleteUser(val, setLoading, setVal);
    }

    return (
        <React.Fragment>
            <DeleteBtn
                id='userDetailDeleteBtn'
                variant={"contained"}
                color={"primary"}
                startIcon={<DeleteIcon/>}
                onClick={handleClickOpen}
            >Delete</DeleteBtn>
            <Dialog
                // fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Really delete user account?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please confirm deletion of the account belonging to {details}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Abort
                    </Button>
                    <Button onClick={handleDelete} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}