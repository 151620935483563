import React from "react";
import InvSummary from "./InvSummary";
import {Grid} from "@mui/material";
import Glass from "../../components/Glass";
import {PageContentArea} from "../../site components/PageContentArea";
import InfoBlock from "./InfoBlock";

export default function DashboardPage() {

    return (
        <PageContentArea>
            <Grid container spacing={0} sx={{height: '100%'}}>
                <Grid item xs={12} sx={{minHeight: '400px'}}>
                    <Grid container spacing={1} sx={{height: '100%',minHeight: '400px'}}>
                        <Grid item xs={12} md={6} sx={{minHeight: '400px'}}>
                            <Glass style={{padding:0, margin:0}}>
                                <InvSummary/>
                            </Glass>
                        </Grid>
                        {/*<Grid item xs={12} md={5} sx={{minHeight: '400px'}}>*/}
                        {/*    <Glass style={{padding:0, margin:0}}>*/}
                        {/*    </Glass>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{height:'50%'}}>
                    <Grid container spacing={1} sx={{height: '100%',minHeight: '400px'}}>
                        <Grid item xs={12} md={6} sx={{minHeight: '400px'}}>
                            <Glass style={{padding:0, margin:0}}>
                                <InfoBlock/>
                            </Glass>
                        </Grid>
                        {/*<Grid item xs={12} md={5} sx={{minHeight: '400px'}}>*/}
                        {/*    <Glass style={{padding:0, margin:0}}>*/}
                        {/*    </Glass>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>
            </Grid>
        </PageContentArea>
    )
}